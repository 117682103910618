<template>

  <div class="card">
    <div class="custom-header " ><div>ورود</div></div>
    <div class="body font" >
      <div class="email">
        <label  v-if="verifyType === 'Email'">آدرس ایمیل</label>
        <label  v-if="verifyType === 'GoogleAuth'">آدرس ایمیل</label>
        <label  v-if="verifyType === 'SMS'">شماره موبایل</label>
        <input type="text" :value="otp.email" class="disabled" disabled />
      </div>
      <div class="code">
        <div>

          <div class="timer-label">
            <label>کد ۶رقمی
              <span v-if="verifyType === 'Email'">ارسال شده به ایمیل</span>
              <span v-if="verifyType === 'GoogleAuth'">شناسایی دوعاملی گوگل</span>
              <span v-if="verifyType === 'SMS'"> ارسال شده به موبایل</span>
            </label>
            <span v-if="verifyType !== 'GoogleAuth' " class="timer">{{Math.floor(countDown/60)}}:{{Math.floor((countDown%60)/10)}}{{(countDown%60)%10}} </span>

          </div>

        </div>
        <input type="number" v-model="otp.code" class="codeinput" />
      </div>
      <button v-if="countDown>0" class="confirmBtn" :disabled="!otp.code"  @click="submit()">تایید</button>
      <button v-else class="confirmBtn"    @click="send()">رسال مجدد کد</button>
    </div>
  </div>
  
</template>

<script>
export default {
  name: 'TwoStepVerify',
  components:{
  },
  data(){
    return{
      verifyType:this.$route.params.type,
      verifyTypes:{
        'Email':'ایمیل',
        'SMS':'شماره موبایل',
        'GoogleAuth':'شناسایی گوگل'
      },
                countDown: 120,
                error: '',
                code: '',
                num: 6,
                otp: {
                    code: '',
                    email: this.state.userInfo.email || this.$cookies.get('username'),
                    phoneNumber:this.state.userInfo.mobilenumber
                }      
      }
  },
    methods: {
            countDownTimer() {
                if (this.countDown > 0) {
                    this.timeOut = setTimeout(() => {
                        this.countDown--
                        this.countDownTimer()
                    }, 1000)
                } else {
                    this.$error('زمان شما به اتمام رسید', '')
                }
            },
            async send() {
                this.state.loading = true
                await this.$axios.post(`/users/resend-otp-code?email=${this.$cookies.get('username')}`)
                const text = {
                    email: 'ایمیل',
                    sms: 'شماره'
                }
                this.$error('کد جدید به ' + text[this.pathMatch] + ' شما ارسال شد.', '', 'success')
                this.$cookies.set('username', this.state.userInfo.email)
                if (this.countDown === 0) {
                    this.countDown = 120
                    this.countDownTimer()
                }

            },
           
            onChange(e) {
                this.otp.code = e
            },
            async submit() {
                if (this.countDown !== 0) {
                    this.state.loading = true
                    const res = await this.$axios.post('/users/check-otp-code', this.otp)
                    if (res.token) {
                        await this.$setToken(res.token)
                        await this.$router.push('/Panel/Authentication')
                    }
                }
                // else{
                //     this.send()
                // console.log(this.otp)
                // }
            },
           
        },
        beforeDestroy() {
            clearTimeout(this.timeOut)
        },
        mounted() {
            if( !this.otp.email ){
                this.$router.push('/login')
            }
            if( this.verifyType !== 'GoogleAuth' ){
                this.countDownTimer()
            }
        } 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.codeinput > input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.timer-label{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.timer{
  opacity: 0.7;
  font-size: 14px;
}
input{
    display: block;
    margin-top:4px;
    margin-bottom:12px;
    background: rgba(255, 255, 255, 0.05);
border-radius: 2px;
color:white;
height:clamp(33px,5vw,41px);
width: 100%;
border-style: hidden;
}


.disabled{
  opacity:0.5;
  direction: ltr;
}
input:focus {
  outline: none !important;
  border: 1px solid #f29a39;
}

.codeinput > input[type="number"] {
  -moz-appearance: textfield;
}


.body{
    padding: 24px;
}

.card {
    text-align: right;
    font-weight: 400;
    height:306px;
     margin-top:20px;
    width: min(432px,95%);
    color: white;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(15px);
  border-radius: 2px;
  
font-style: normal;
font-weight: normal;
}

.font{
   
    font-size: clamp(13px, 1.7vw, 16px); 

}
</style>
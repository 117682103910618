<template>
<div class="btn-container">
    <button :disabled='disabled' :style="{color:color,background:background}" :class="{disabled:disabled}" @click="$emit('clicked')">
    <slot>

    </slot>
</button>

</div>

  
</template>

<script>
export default {
  name: 'CardButton',
  props:{
      color:{type:String},
      background:{type:String},
      click:{type:Function},
      disabled:{type:Boolean}
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
button{
    height: 41px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 2px;
    font-size: clamp(13px,1.3vw,16px);
    border-bottom-right-radius: 2px;
    background: linear-gradient(258.51deg, #FF951F -13.62%, #F7BD68 62.17%, #FF951F 144.54%);
}

.disabled{
    opacity: 0.7;
}
.btn-container{
    width: 100%;
}
</style>

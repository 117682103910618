<template>
  <div class="download">
       <diV> <div class="head"><div/>دانلود نرم افزار <div/></div></diV>
       <div class="container">
           <div class="download-links">
                 <div class="passage">
                   با اپلیکیشن ویستا میتوانید به راحتی و درهرزمان به حساب کاربری خود دسترسی داشته باشید و در درکوتاه ترین زمان خرید و فروش ارز های خود را مدیریت کنید.
               </div>
               <div class="links">
                   <div>
                        <button class="btn "><img src="../../assets/auth/directdl.png"/>دانلود مستقیم</button>
                        <button class="btn "><img src="../../assets/auth/playstore.png"/>گوگل پلی</button>

                   </div>
                   <div>
                        <button class="btn "><img src="../../assets/auth/bazar.png"/>دانلود از بازار</button>
                        <button class="btn "><img src="../../assets/auth/myket.png"/>مایکت</button>
                

                   </div>
                  
                

               </div>
             
           </div>
           <div class="phone-container"><img class="phone" src="../../assets/auth/phones.png"/></div>
       </div>

  </div>
</template>

<script>
export default {
  name: 'AppDownload',
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.container{
    display: flex;
    width:100%;
    flex-wrap: wrap;
    justify-content: space-between;
    
}

.container > div{
    flex-grow: 1;
}
.bazar::before{
    padding-top:5px;
     content:url('../../assets/auth/bazar.png')
}.myket::before{
    padding-top:5px;
    content:url('../../assets/auth/myket.png')
}.play::before{
    padding-top:5px;
    content:url('../../assets/auth/playstore.png')
}.direct::before{
    padding-top:5px;
    
    content:url('../../assets/auth/directdl.png')
}


.phone-container{
    display: flex;
    flex-wrap: wrap;

   
   flex-basis: 220px;
  
  
   
    justify-content: flex-end;
}
.links{
    display: flex;
    flex-wrap: wrap;
   
    flex-direction: column;
    row-gap: 44px;
    justify-content: flex-start;
}

.phone{
    max-width:350px;
    height: auto;
     width: 100%;
    display: block;
   
}




.download-links{
  
    display: flex;
    row-gap: 46px;
     margin-top:60px;
     padding-bottom:70px;
    align-items: flex-start;
  
    padding-right: 35px;
    font-size: clamp(13px,1.4vw,16px);
    font-weight:400;
    
   
    flex-direction:column;
    
}

.passage{
 max-width:510px;
}
.btn{
    background: rgba(255, 255, 255, 0.02);
/* Gold */
white-space: nowrap;
padding:12px;
color:white;
justify-content: flex-start;
height: 60px;
border: 1px solid #F29A39;
width:188px;

column-gap:9px;
font-size: clamp(14px,1.5vw,19px);
font-weight: 500;
display: flex;
align-items: center;
border-radius: 5px;
}
.download{
    display: flex;
    flex-direction: column;
    width: 100%;
  

    align-items: center;
}

.links > div{
    display: flex;
    flex-wrap:wrap;
    justify-content: center;
    row-gap:44px;
    column-gap: 42px;;
}
.head{
    display: flex;
    column-gap: 4px;
    margin-bottom: 24px;
    text-align: center;
    font-size: clamp(17px,1.7vw,20px);
    font-weight: 500;
    justify-content: center;
    align-items: center;
}
.head > div{
    border-bottom: 2px solid #F29A39;
    width: 16px;
    border-radius: 2px;}
</style>

<template>
<form class="authentication-page" v-if="profile" @submit.prevent="submit()">
    <PersonalFields :readOnly="readonly" :data.sync="userInfo" />
    <BankInformation  :key="change" :bankAccounts.sync="userInfo.bankAccounts" />
    <ProofUpload  v-if="!readonly" :data.sync="userInfo" />

    <div class="confirm-section">
        <div v-if="userInfo.authenticationType=='NOT_COMPLETE' || userInfo.authenticationType=='NOT_AUTHORIZED'" class="register-btns">
            <button class="cancelBtn" @click="$router.push('/Auth/SignUp')">انصراف</button>
            <button :class="{'confirmBtn':true,'disabled-btn':disabled}" :disabled='disabled' type="submit">تایید اطلاعات</button>
        </div>
        <button :class="{'confirm-data-btn':true,'disabled-btn':disabled}" type="submit" v-else class="confirmBtn update-btn">اعمال تغییرات</button>

    </div>

</form>
</template>

<script>
const statusLabel = {
    CONFIRMED_IDENTITY: 'اطلاعات هویتی تایید شده',
    AUTHORIZED: 'احراز هویت شده',
    UNAUTHORIZED: 'اطلاعات تکمیل نشده',
    PENDING: 'در انتظار تایید',
    NOT_COMPLETE: 'احراز هویت تکمیل نشده',
    UNIDENTIFIED: 'اطلاعات هویتی تایید شده',
    INFORMATION_CHANGED_PENDING: 'در انتظار تایید',
    INFORMATION_CHANGED: 'احراز هویت تکمیل نشده'
}
import BankInformation from './BankInformation'
import PersonalFields from './PersonalFields'
import ProofUpload from './ProofUpload'
export default {
    name: 'Authentication',
    components: {
        ProofUpload,
        BankInformation,
        PersonalFields
    },
    data() {
        return {
            bankChange: 'noop',
            profile: true,
            userInfo: {
                // authorized: this.state.userInfo.authorized,
                // pending: this.state.userInfo.pending,
                authenticationType: '',
                firstName: '',
                lastName: '',
                email: '',
                nationalCode: '',
                birthDay: '',
                mobileNumber: '',
                telephone: '',
                address: '',
                bankAccounts: [],
                image: '',
            },
            duplicateBank: '',
            change: 0,
        }
    },
    computed: {
        readonly() {
            return ['AUTHORIZED', "INFORMATION_CHANGED", "CONFIRMED_IDENTITY"].includes(this.state.userInfo.authenticationType)
        },
        disabled() {
            for (let key in this.userInfo) {
                if (['firstName', 'lastName', 'email', 'nationalCode', 'birthDay', 'mobileNumber', 'telephone', 'address', 'bankAccounts', 'image'].includes(key)) {
                    if (key === 'bankAccounts') {
                        for (let data of this.userInfo.bankAccounts) {
                            console.log(data.cardNumber)
                            if (!data.shebaCode || !data.cardNumber || data.cardNumber.replace(/-/g, '').length < 16) {
                                console.log('.')
                                return true;
                            }
                        }
                    }
                    // else if (key === 'address') {
                    //     let re = /\d{10}/;
                    //     if (this.userInfo.address && this.userInfo.address.replace(/ /ig, '').length < 21 || !re.test(this.userInfo.address)) {
                    //         return true
                    //     }
                    // }
                    else if (key === 'nationalCode') {
                        if (this.userInfo[key] < 10) {
                            return true;
                        }
                    } else if (!this.userInfo[key]) {
                        return true;
                    }
                }
            }
            return false
        },
        label() {
            return statusLabel[this.state.userInfo.authenticationType]
        },

    },
    methods: {
        async check() {
            this.state.loading = true;
            const res = await this.$axios("/users/account?imageNeeded=true")
            this.userInfo = res.baseDTO
            this.userInfo.bankAccounts = res.baseDTO.bankAccounts.length ?
                res.baseDTO.bankAccounts :
                []
            this.change++
            this.state.loading=false
        },
        removeDash(items) {
            for (let item of items) {
                item.cardNumber = item.cardNumber.replace(/-/gi, '');
            }
        },
        bankCheck(items) {

            for (let item of items) {

                if (!item.cardNumber || !item.shebaCode) {
                    this.$error('اطلاعات ناقص', "شماره شبا یا کارت بانکی را وارد نکرده اید");
                    return false;
                } else {
                    return true;
                }
            }
        },
        dataURLtoFile(dataurl, filename) {

            let arr = dataurl.split(":");
            let mime = arr[0].match(/:(.*?);/)[1];
            let bstr = atob(arr[1]);

            let n = bstr.length;
            let u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, {
                type: mime
            });
        },
        async submit() {
            console.log(this.userInfo)
            this.state.loading = true;
            // this.removeDash(this.userInfo.bankAccounts);
            let form_data = new FormData();
              //    for (var value of form_data.values()) {
            //  console.log(value);
            //       }
            console.log(this.userInfo)
            for (let key in this.userInfo) {
                if (key === 'image') {
                    if (typeof this.userInfo.image !== 'string') {
                        form_data.append("file", this.userInfo.image);
                    } else {
                        form_data.append("file", this.dataURLtoFile(this.userInfo.image, "image"));
                    }
                }
                form_data.append(key, this.userInfo[key]);
            }

            form_data.append("bankAccountsString", JSON.stringify(this.userInfo.bankAccounts));
          

            if (this.bankCheck(this.userInfo.bankAccounts)) {
                try {

                    let vm = this
                    const config = {
                        onUploadProgress: function (progressEvent) {
                            vm.state.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        }
                    }
                    const res = await this.$axios.post("/users/account", form_data, config)

                    this.state.userInfo.authenticationType = res.baseDTO.authenticationType
                    this.state.userInfo.email = res.baseDTO.email
                    this.state.userInfo.authorized = res.baseDTO.authenticationType === "AUTHORIZED"
                    this.state.userInfo.pending = res.baseDTO.authenticationType === "PENDING"
                    console.log(res.message);
                    await this.$error('', 'اطلاعات با موفقیت ذخیره شد', 'success')

                    if (res.message.includes("need to be verified")) {
                        this.state.loading = true
                        await this.$axios(`/finotech/send-sms-to-customer?email=${this.userInfo.email}`)
                        this.$router.push('/fino-verifying')
                    } else {
                        this.$getuserInfo()
                    }
                } catch (e) {
                    if (e.response && e.response.data.message.includes("ShebaCode or CardNumber Is For Another User")) {
                        this.$error('', ' شماره شبا یا کارت قبلا وارد شده است.')
                        this.duplicateBank = e.response.data.message;
                    }
                    console.log(e)
                }
            }
        },
    },
    mounted() {
        this.check()

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.confirmBtn {
    margin-top: 0;
}

.cancelBtn {
    color: white;
    border-radius: 2px;
    border: 1px solid #F29A39;
    font-weight: 500;
    background: rgba(255, 255, 255, 0.05);
    height: 41px;
}

.update-btn {
    max-width: 383px;
    width: 100%;
}

.register-btns>button {
    max-width: 191px;

    flex-basis: 90px;
    flex-grow: 1;
}

.disabled-btn {
    opacity: 0.7;
}

.register-btns {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 24px;
}

.confirm-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

}

.icon {
    margin-top: 10px;
    cursor: pointer;
}

.authentication-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 0px 30px 0px;
    row-gap: 24px;

}

.records-upload {
    font-size: 14px;
}

::v-deep .page {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #1E232E;
    border: 2px solid rgba(255, 255, 255, 0.05);
    box-sizing: border-box;
    border-radius: 2px;
}

::v-deep input {
    height: 41px;
    width: 100%;
    color: white;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 2px;

}

::v-deep input:focus {

    border: 1px solid #f29a39;
}

::v-deep .cardbody {
    display: flex;
    padding: 24px;
    flex-direction: column;
    row-gap: 24px;

    color: white;
    font-weight: normal;
    text-align: right;
}
</style>

<template>
<div class="tables">

  
<DropFilter class="f-3" @choose="(value)=>{activeTableType=value.value}" :items="activeTableTypes"/>    
<div  class="table-container">
  <div class="table-title">سفارشات فعال </div>
    <table  v-if="activeTableType=='EZTrade'">
        <tr class="table-header">
            <td class="resp-3">نوع سفارش</td>
            <td>رمزارز </td>
            <td class="resp-3">قیمت واحد<span class="tag">(تومان)</span></td>
            <td> مقدار </td>
            <td class="resp-3">قیمت کل<span class="tag">(تومان)</span></td>
            <td class="resp-1">زمان </td>
            <td class="resp-2">پیگیری </td>
            <td class="resp-3">وضعیت </td>
        </tr>
        <tbody>
            <tr class="table-row" v-for="(order,index) in filterOrders()" :key="index">
                <td :class="{'resp-3':true,'depsoit-buy':order.tradeType=='BUY','withdraw-sell':order.tradeType=='SELL'}">{{dict[order.tradeType]}}</td>
                <td>
                        <span>{{$coinLabel[order.coinName]}}</span>
                </td>
                <td class="resp-3">{{order.unitPrice.toLocaleString()}}</td>
                <td dir="ltr">{{order.amount}}&nbsp;{{$coinUnit[order.coinName]}} </td>
                <td class="resp-3">{{order.totalPrice.toLocaleString()}}</td>
                <td class="resp-1">{{order.date}}</td>
                <td class="resp-2">{{order.check}}</td>
                <td  class="resp-3" v-if="order.condition=='SUCCESS'"><img src="../../../assets/icons/success.svg"/></td>
                <td  class="resp-3" v-if="order.condition=='FAIL'"><img src="../../../assets/icons/fail.svg"/></td>
            </tr>
        </tbody>
    </table>   


 
    <table  v-if="activeTableType=='ADVTrade'" >
        <tr class="table-header">
            <td>نوع سفارش</td>
            <td>رمزارز</td>
            <td class="resp-3">قیمت واحد<span class="tag">(تومان)</span></td>
            <td> مقدار</td>
            <td class="resp-3">قیمت کل<span class="tag">(تومان)</span></td>
            <td class="resp-1">کارمزد</td>
            <td class="resp-1">زمان</td>
            <td  class="resp-3">وضعیت</td>
        </tr>
        <tbody>
            <tr class="table-row" v-for="(order,index) in filterOrders()" :key="index">
               <td :class="{'depsoit-buy':order.tradeType=='BUY','withdraw-sell':order.tradeType=='SELL'}">{{dict[order.tradeType]}}&nbsp;-&nbsp;{{dict[order.marketType]}}</td>
               <td>
                    <div class="coin-title">
                        <span>{{$coinLabel[order.coinName]}}</span>
                    </div>
                </td>
                <td class="resp-3">{{order.unitPrice.toLocaleString()}} </td>
                <td dir="ltr">{{order.amount}}&nbsp;{{$coinUnit[order.coinName]}} </td>
                <td class="resp-3">{{order.totalPrice.toLocaleString()}}</td>
                <td class="resp-1">{{order.commission}}</td>
                <td class="resp-1">{{order.date}}</td>
                <td  class="resp-3" v-if="order.condition=='SUCCESS'"><img src="../../../assets/icons/success.svg"/></td>
                <td  class="resp-3" v-if="order.condition=='FAIL'"><img src="../../../assets/icons/fail.svg"/></td>
            </tr>
        </tbody>
    </table>   



    <table v-if="activeTableType=='WMPM'"  >
        <tr class="table-header">
            <td>نوع سفارش</td>
            <td>رمزارز</td>
            <td class="resp-3">قیمت واحد<span class="tag">(تومان)</span></td>
            <td> مقدار</td>
            <td class="resp-3">قیمت کل<span class="tag">(تومان)</span></td>
            <td class="resp-1">زمان</td>
            <td class="resp-2">آدرس</td>
            <td class="resp-2">جزئیات</td>
            <td  class="resp-3">وضعیت</td>
        </tr>
        <tbody>
            <tr class="table-row" v-for="(order,index) in filterOrders()" :key="index">
               <td :class="{'depsoit-buy':order.tradeType=='BUY','withdraw-sell':order.tradeType=='SELL'}">{{dict[order.tradeType]}}</td>
               <td>
                    <div class="coin-title">
                        <span>{{$coinLabel[order.coinName]}}</span>
                    </div>
                </td>
                <td class="resp-3">{{order.unitPrice.toLocaleString()}}</td>
                <td dir='ltr'>{{order.amount}}&nbsp;$</td>
                <td class="resp-3">{{order.totalPrice.toLocaleString()}}</td>
                <td class="resp-1">{{order.date}}</td>
                <td class="resp-1">{{order.address}}</td>
                <td class="resp-1"><img src="../../../assets/auth/eye.svg"/></td>
                <td  class="resp-3" v-if="order.condition=='SUCCESS'"><img src="../../../assets/icons/success.svg"/></td>
                <td  class="resp-3" v-if="order.condition=='FAIL'"><img src="../../../assets/icons/fail.svg"/></td>
            </tr>
        </tbody>
    </table>   
     </div>



<DropFilter class="f-3" @choose="(value)=>{historyTableType=value.value}" :items="historyTableTypes"/>    
<div  class="table-container">
  <div class="table-title">تاریخچه معاملات  </div>
    <table v-if="historyTableType=='EZTrade'">
        <tr class="table-header">
            <td class="resp-3">نوع سفارش</td>
            <td>رمزارز </td>
            <td class="resp-3">قیمت واحد<span class="tag">(تومان)</span></td>
            <td> مقدار </td>
            <td class="resp-3">قیمت کل<span class="tag">(تومان)</span></td>
            <td class="resp-1">زمان </td>
            <td class="resp-2">پیگیری </td>
            <td class="resp-3">وضعیت </td>
        </tr>
        <tbody>
            <tr class="table-row" v-for="(order,index) in filterOrders()" :key="index">
                <td :class="{'resp-3':true,'depsoit-buy':order.tradeType=='BUY','withdraw-sell':order.tradeType=='SELL'}">{{dict[order.tradeType]}}</td>
                <td>
                        <span>{{$coinLabel[order.coinName]}}</span>
                </td>
                <td class="resp-3">{{order.unitPrice.toLocaleString()}}</td>
                <td dir="ltr">{{order.amount}}&nbsp;{{$coinUnit[order.coinName]}} </td>
                <td class="resp-3">{{order.totalPrice.toLocaleString()}}</td>
                <td class="resp-1">{{order.date}}</td>
                <td class="resp-2">{{order.check}}</td>
                <td  class="resp-3" v-if="order.condition=='SUCCESS'"><img src="../../../assets/icons/success.svg"/></td>
                <td  class="resp-3" v-if="order.condition=='FAIL'"><img src="../../../assets/icons/fail.svg"/></td>
            </tr>
        </tbody>
    </table>   


 
    <table v-if="historyTableType=='ADVTrade'" >
        <tr class="table-header">
            <td>نوع سفارش</td>
            <td>رمزارز</td>
            <td class="resp-3">قیمت واحد<span class="tag">(تومان)</span></td>
            <td> مقدار</td>
            <td class="resp-3">قیمت کل<span class="tag">(تومان)</span></td>
            <td class="resp-1">کارمزد</td>
            <td class="resp-1">زمان</td>
            <td  class="resp-3">وضعیت</td>
        </tr>
        <tbody>
            <tr class="table-row" v-for="(order,index) in filterOrders()" :key="index">
               <td :class="{'depsoit-buy':order.tradeType=='BUY','withdraw-sell':order.tradeType=='SELL'}">{{dict[order.tradeType]}}&nbsp;-&nbsp;{{dict[order.marketType]}}</td>
               <td>
                    <div class="coin-title">
                        <span>{{$coinLabel[order.coinName]}}</span>
                    </div>
                </td>
                <td class="resp-3">{{order.unitPrice.toLocaleString()}} </td>
                <td dir="ltr">{{order.amount}}&nbsp;{{$coinUnit[order.coinName]}} </td>
                <td class="resp-3">{{order.totalPrice.toLocaleString()}}</td>
                <td class="resp-1">{{order.commission}}</td>
                <td class="resp-1">{{order.date}}</td>
                <td  class="resp-3" v-if="order.condition=='SUCCESS'"><img src="../../../assets/icons/success.svg"/></td>
                <td  class="resp-3" v-if="order.condition=='FAIL'"><img src="../../../assets/icons/fail.svg"/></td>
            </tr>
        </tbody>
    </table>   



    <table   v-if="historyTableType=='WMPM'">
        <tr class="table-header">
            <td>نوع سفارش</td>
            <td>رمزارز</td>
            <td class="resp-3">قیمت واحد<span class="tag">(تومان)</span></td>
            <td> مقدار</td>
            <td class="resp-3">قیمت کل<span class="tag">(تومان)</span></td>
            <td class="resp-1">زمان</td>
            <td class="resp-2">آدرس</td>
            <td class="resp-2">جزئیات</td>
            <td  class="resp-3">وضعیت</td>
        </tr>
        <tbody>
            <tr class="table-row" v-for="(order,index) in filterOrders()" :key="index">
               <td :class="{'depsoit-buy':order.tradeType=='BUY','withdraw-sell':order.tradeType=='SELL'}">{{dict[order.tradeType]}}</td>
               <td>
                    <div class="coin-title">
                        <span>{{$coinLabel[order.coinName]}}</span>
                    </div>
                </td>
                <td class="resp-3">{{order.unitPrice.toLocaleString()}}</td>
                <td dir='ltr'>{{order.amount}}&nbsp;$</td>
                <td class="resp-3">{{order.totalPrice.toLocaleString()}}</td>
                <td class="resp-1">{{order.date}}</td>
                <td class="resp-1">{{order.address}}</td>
                <td class="resp-1"><img src="../../../assets/auth/eye.svg"/></td>
                <td  class="resp-3" v-if="order.condition=='SUCCESS'"><img src="../../../assets/icons/success.svg"/></td>
                <td  class="resp-3" v-if="order.condition=='FAIL'"><img src="../../../assets/icons/fail.svg"/></td>
            </tr>
        </tbody>
    </table>   
  </div>  
    
    

    
   
</div>
 
</template>

<script>
import dataSet from '../../../library/dataSet.json'
import DropFilter from './DropFilter'
export default {
  name: 'Trades',
  components:{
      DropFilter,
  },
 
  methods:{
    filterOrders(){
      return this.orders.filter(order=>{
       
        //   if(true) {
            
              return order
        //   }
      })
    }
  },
  data(){
    return{
        orders:dataSet.dataSet,
        // marketType:'',
        currencyType:'RIAL',
        is_open:true,
        // buy_sell:'',
        dict:{'SELL':'فروش','BUY':'خرید','LIMIT':'حد','MARKET':'بازار'},
        // coins:[
        //     {label:'نوع ارز',value:''},
        //     {label:'بیت کوین',value:'BTC'},
        //     {label:'اتریوم کلاسیک',value:'ETC'},
        //     {label:'اتریوم',value:'ETH'},
        // ],
        // coin:{label:'',value:''},
        // marketTypes:[
        //     {label:'نوع بازار',value:''},
        //     {label:'بازار',value:'MARKET'},
        //     {label:' حد',value:'LIMIT'},
        // ],
        activeTableType:'EZTrade',
        historyTableType:'EZTrade',
        activeTableTypes:[
            {label:'معامله آسان',value:'EZTrade'},
            {label:'معامله پیشرفته',value:'ADVTrade'},
            {label:'معامله وب‌ مانی و پرفکت ‌مانی',value:'WMPM'},
        ], 
        historyTableTypes:[
            {label:'معامله آسان',value:'EZTrade'},
            {label:'معامله پیشرفته',value:'ADVTrade'},
            {label:'معامله وب‌ مانی و پرفکت ‌مانی',value:'WMPM'},
        ],
        // dealType:'MARKET'

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

 .table-container{
    margin-top: 20px;
    margin-bottom: 20px;
     height: 300px!important;
    max-height: 300px!important;
    overflow-y:scroll ;
}
.sf{
    z-index: 23!important;
}
.page-header{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
    justify-content: space-between;
    /*color: var(--history-page-header)!important;*/
}
   .table-filter{
    max-width: 220px;
    flex-grow: 1;
    /*color: var(--history-page-header) !important;*/

}

 .table-title{
    color: #EFEFEF;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(37, 67, 130, 0.06);
    border-radius: 2px;
    font-size: clamp(14px,1.5vw,16px);
    backdrop-filter: blur(15px);
    height: 41px;
    
}
 .filters{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    row-gap: 10px;

    flex-wrap: wrap;
}

 .right-filter{
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    align-items: center;
    column-gap: 10px;
    
    
}
.history{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
}
 .coin-title{
    display: flex;
    align-items: center;
    column-gap: 2px;
    justify-content: center;
    font-family: 'YekanBold';
}

 .table-header > td:last-child{
    text-align: left;
    padding-left: 12px;
}


 .table-header > td:first-child{
    text-align: right;
    padding-right: 12px;
}

 .table-row > td:last-child{
    text-align: left;
    padding-left: 24px;

}
 .table-row > td:first-child{
    text-align: right;
    padding-right: 24px;

}

 .coin-icon{
    width: 16px;
    height: 16px;
    padding-bottom: 3px;
}

 .tradeType{
    /*color: var(--history-blue);*/
}

 .table-row{
    font-size: clamp(12px,1.3vw,14px);
    color: #EFEFEF;
    background: rgba(136, 136, 136, 0.05);
    border-bottom: 1px solid #5a5959;
}
 table{
    width: 100%;
    border-collapse: collapse;
    backdrop-filter: blur(15px);
}

.tables{
    margin-top: 40px;
}
 .table-header{
    background: rgba(37, 67, 130, 0.06);
    border-radius: 2px;
    font-size: clamp(12px,1.3vw,14px);
    color: #EFEFEF;

}

 tbody{
   
}

 .tag{
    font-size:clamp(11px,1.1vw,13px);
    font-family: 'IRANSansLight';
    opacity: 0.6;
    
}
 td{
        padding: 10px;
}

 .withdraw-sell{
    color: #D62B2B;
    
}
 .depsoit-buy{
    color: #0B8710;

}
 .status{
    display: flex;
    align-items: center;
    column-gap: 6px;
}




 @media(max-width:1200px){
   
    .resp-1{
        display: none;
    } 
}
@media(max-width:900px){
    .resp-2{
        display: none;
    } 
}

@media(max-width:600px){
    .resp-3{
        display: none;
    }
}


 .f-1{
    z-index: 22!important;
}
 .f-2{
    z-index: 21!important;
}
 .f-3{
    z-index: 20!important;
}


.mar{
    width:600px;
}


 @media(max-width:1200px){
   
    .resp-1{
        display: none;
    } 
}
@media(max-width:900px){
    .resp-2{
        display: none;
    } 
}

@media(max-width:600px){
    .resp-3{
        display: none;
    }
}
</style>
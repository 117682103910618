<template>
<div class="contents">
    <div class="header-back-ground"> 
        <div class="parent-header content">
                <Header/>
                <CryptosCarousel/>
        </div>
    
   </div>
   


    <Comments class="content"/>
    <Table class="content"/>
   <div class="homebg homebg2"/>

   


   
    <Advantages class="content"/>
   
     <diV class="vistaDeals content">
        <div class="text">
            <div class="head"><div/>معاملات ویستا<div/></div>
            <div>ویستا با پشتیبانی از بیش از ۰۰۳ ارز در صرافی امکان معامله ی آسان و بدون محدودیت را برای شما عزیزان فراهم آورده است.</div>
            <div>علاوه بر ارزهای دیجیتال موجود در سایت امکان معامله ی وب مانی و پرفکت مانی نیز برای کاربران فراّم شده است تا در کمترن زمان ممکن و با استفاده از یکی از جامع ترین ابزارهای معامله، خرید و فروش خود را انجام دهند.</div>
            <div>معامله امن و سریع ویستا در هر دو. پلتفرم سایت و صرافی امکان معامله ی ساده و جامع در این بستر را برای شما فراهم آورده.</div>
        </div>
        <div class="coins"><img src="../../assets/auth/Cryptos.svg"/> </div>
        </diV>
   <div class="homebg homebg3"/>
    <WorkSteps class="content"/>
      <AppDownload class="content"/>
       

 
  
    <FAQ class="content"/>
    <WeblogContents class="content"/>
    <div class="tradeBtn content"><button @click="$router.push('/Auth/Login')" class="btn">شروع معامله</button></div>
    



</div>
  
</template>

<script>
import AppDownload from './AppDownload'
import Header from './Header'
import CryptosCarousel from './CryptosCarousel'
import Comments from './Comments'
import WorkSteps from './WorkSteps'
import FAQ from './FAQ'
import Advantages from './Advantages'
import WeblogContents from './WeblogContents'
import Table from './Table'
export default {
  name: 'Home',
  components:{
      Header,CryptosCarousel,
    Comments,
    WorkSteps,
    Advantages,
    Table,AppDownload,
    FAQ,
    WeblogContents
  }
 
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.content{
    width: 82%;
    box-sizing: border-box;
    z-index: 1;
    
   

}

.homebg{
    display: flex;
    flex-direction: column;
  
    position: absolute;
    background: url('../../assets/auth/homeBg3.png');
    background-repeat: no-repeat;
  
  
    
     opacity: 0.2;
     min-height:1000px ;
    width: 200%;
  
    overflow: hidden;
}
.homebg2{
     margin-top: -1720px;
     left: 60%;
 
}
.homebg3{
   margin-top: 620px;
   left: -200px;
}
.btn{
    background: linear-gradient(258.51deg, #FF951F -13.62%, #F7BD68 62.17%, #FF951F 144.54%);
    height: 41px;
    max-width: 393px;
    border-radius: 2px;
    width: 100%;

}
.tradeBtn{
    display: flex;
    justify-content: center;
    align-items: center;
}
.header-back-ground{
background-image:url('../../assets/auth/landingbg.svg');
  min-height:800px ;
  width: 100%;
  background-repeat: no-repeat;
    overflow: hidden;
  margin-top: -88px;

  
}

.parent-header{
    display: flex;
  
    flex-direction: column;
   margin-right: 8.5%;
   row-gap: 131px;
    margin-top: clamp(150px,20vw,285px);
}
.vistaDeals{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 30px;
    font-size: clamp(13px,1.2vw,16px);
}

.coins{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.coins > img{
    height: auto;
    width: 100%;
    max-width:531px;
    display: block;
}
.vistaDeals > div{
    flex-grow: 1;
}

.vistaDeals > .text{
    
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    max-width: 562px;
}


.head{
    display: flex;
    column-gap: 4px;
    margin-bottom: 24px;
    text-align: center;
    font-size: clamp(17px,1.7vw,20px);
    font-weight: 500;
    justify-content: center;
    align-items: center;
}
.head > div{
    border-bottom: 2px solid #F29A39;
    width: 16px;
    border-radius: 2px;
}
.contents{
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    row-gap: 56px;
       overflow-y: auto;
  overflow-y: overlay;
  
  
  
    color:white;
    padding-bottom:134px;
    text-align: right;
}

</style>

<template>
  <div class="settings">
  

    <div class="passChange">
      <CardHeader>تغییر رمز عبور</CardHeader>
      <form class="body"  @submit.prevent="submit()">
        <div class="dataField">
          <label>رمز عبور فعلی</label>

          <div class="input">
            <input  @input="passError = ''" v-model="oldpassword" :type="passShow ? 'text' : 'password'" />
            <div
              :style="{ opacity: passShow ? '1' : '0.5' }"
              v-on:click="passShow = !passShow"
              class="eye"
            />
          </div>
        </div>
        <div class="dataField">
          <label>رمز عبور جدید</label>
          <div class="input">
            <input @input="pass1Error = ''" v-model="newpassword1" :type="newPassShow ? 'text' : 'password'" />
            <div
              :style="{ opacity: newPassShow ? '1' : '0.5' }"
              v-on:click="newPassShow = !newPassShow"
              class="eye"
            />
          </div>
        </div>

        <div class="dataField">
          <label>تکرار رمز عبور </label>
          <div class="input">
            <input  @input="pass2Error = ''" v-model="newpassword2"  :type="rePassShow ? 'text' : 'password'" />
            <div
              :style="{ opacity: rePassShow ? '1' : '0.5' }"
              v-on:click="rePassShow = !rePassShow"
              class="eye"
            />
          </div>
        </div>

        <button type="submit" class="btn" :disabled="!oldpassword||
                !newpassword1||
                !newpassword2">تغییر رمز عبور</button>
      </form>
    </div>
    
   
  </div>
</template>

<script>

export default {
  name: "ُSettings",
 
  data() {
    return {
      modalShow:false,
      passShow: false,
      rePassShow: false,
      newPassShow: false,
      oldpassword: '',
      newpassword1: '',
      newpassword2: '',
      passError: '',
      pass1Error: '',
      pass2Error: '',
     
    };
  },
 methods:{
     async submit() {

            let passSent = {
                oldPassword: this.oldpassword,
                password: this.newpassword1
            }
            await this.check2Pass()
            if (this.newpassword1 && this.newpassword2) {
                if (this.checkPass())
                    this.state.loading = true
                try {
                    const res = await this.$axios.post(`/users/change-password`, passSent)
                    if (res.message === "password changed successfully") {
                        this.$error('رمز شما تغییر یافت', '', 'success')
                        this.oldpassword = ''
                        this.newpassword1 = ''
                        this.newpassword2 = ''
                    }
                } catch (error) {
                    if (error.response.data.message === 'Old Password Is Incorrect') {
                        this.passError = 'رمز قبلی اشتباه است.'
                    }
                }
            }
        },
        checkPass() {
            if (!this.oldpassword) {
                this.passError = 'رمز فعلی اجباری';
            } else {
                this.passError = ''
            }
            if (this.newpassword1) {
                if (this.newpassword1.length < 8) {
                    this.pass1Error = 'حداقل 8 کاراکتر';
                } else if (!this.validPass(this.newpassword1)) {
                    this.pass1Error = 'ترکیبی از حروف کوچک و بزرگ و عدد';
                } else if (!this.newpassword2) {
                    this.pass1Error = ''
                    this.pass2Error = 'تکرار رمز اجباری';
                } else if (this.newpassword2 !== this.newpassword1) {
                    this.pass1Error = ''
                    this.pass2Error = 'تکرار رمز مطابقت ندارد';
                } else {
                    this.pass2Error = ''
                }
            }
            if (!this.passError.length && !this.pass1Error.length && !this.pass2Error.length) {
                return true;
            }
        },
        async check2Pass() {
            if (!this.$validPass(this.oldpassword) || this.oldpassword.length < 8) {
                this.$error('اطلاعات وارد شده اشتباه است', 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد')
                return Promise.reject('wrong pass : ' + this.oldpassword)
            }
            if (this.newpassword1 !== this.newpassword2) {
                this.$error('اطلاعات وارد شده اشتباه است', 'تکرار رمز اشتباه است')
                return Promise.reject('repeat failed : ' + this.newpassword1 + '!==' + this.newpassword2)
            }
        },
        validPass(password) {
            const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
            return re.test(password);
        }
 }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label{
  padding: 0px 0px 5px 0px;
  font-size: clamp(13px,1.3vw,16px);
}
.input-div {
 
 font-size: clamp(12px,1vw,14px);
  white-space:nowrap;
  cursor: pointer;
 
  display: flex;
  align-items: center;
  column-gap:8px;
}
.outer-circle{
  border-radius: 50%;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  min-width: 13px;
  min-height:13px;
  max-width: 13px;
  max-height:13px;

background-image:linear-gradient(89.43deg, #8F5D36 0.33%, #CD8F57 27.7%, #DCA15F 49.09%, #CD8F57 74.6%, #9D663E 99.35%);
}

.inner-circle{
  border-radius:inherit;
  display: flex;
  justify-content: center;
  align-items: center;
   background: rgba(37, 42, 59, 0.8);

   min-width: 11px;
  min-height:11px;
  max-width: 11px;
  max-height:11px;
  
 
}
.circle{
  border-radius: inherit;
  content: '';
  min-width: 9px;
  min-height:9px;
 
background: linear-gradient(89.43deg, #8F5D36 0.33%, #CD8F57 27.7%, #DCA15F 49.09%, #CD8F57 74.6%, #9D663E 99.35%);
}
.dataField {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-width: 200px;
  flex: 1;
  justify-content: center;
}
.jk {
  display: flex;
}
.passChange {
  margin-top: 16px;
  background: rgba(136, 136, 136, 0.05);
  backdrop-filter: blur(15px);
  border-radius: 2px;
  color: white;
}


.btn {
  height: 41px;
  padding-left: 40px;
  padding-right: 40px;

  background: linear-gradient(258.51deg, #FF951F -13.62%, #F7BD68 62.17%, #FF951F 144.54%);
  border-radius: 2px;
}

.passChange .body {
  display: flex;
  text-align: right;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  padding: 16px;
  row-gap: 15px;
  column-gap: 32px;
}

input[type="password"],
input[type="text"] {
  color: white;
  height: 41px;
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 2px;
}

.eye {
  position: absolute;
  left: 5px;

  cursor: pointer;
  content: url("../../../assets/auth/eye.svg");
}

.input {
  display: flex;
  align-items: center;

  position: relative;
}

.describe{
  
  font-size:clamp(12px,1vw,14px) ;
  flex-direction: column;
 
}
</style>
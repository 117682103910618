<template>
      <div class="bar">
        <div  class="cryptoTitle" @click="dropShow=!dropShow">
        <img   class="cryptoIcon" :src="require('../../../../../public/coins/'+tradeFrom+'.png')"/>
        <span>{{$coins[tradeFrom].persianName}} - {{dict[tradeTo]}}</span>
        <span  class="symbol">{{$coins[tradeFrom].code}}</span>
        <img :class="{'drop-open':dropShow}" src="../../../../assets/trades/arrowdown.svg"/>
    </div>
       
        <diV  v-if="dropShow" class="dropdown">
           <SwitchComp  :theme=1 :choosed="tradeTo" @choose="(choice)=>$router.push('/Panel/AdvTrade/'+choice+'/'+tradeFrom)" 
               :items="[{label:'تتر',value:'TETHER'},{label:'تومان',value:'TOMAN'}]"/>
           <div class="items">
            <div class="dropItem" v-for="(coin,index) in cryptos" :key="index" @click="$router.push('/Panel/AdvTrade/'+tradeTo+'/'+$coin2Snake[coin.relatedCoin]);dropShow=false">
                <img   class="cryptoIcon" :src="require('../../../../../public/coins/'+coin.relatedCoin+'.png')"/>
                <span>{{$coinLabel[coin.relatedCoin]}} - {{dict[tradeTo]}}</span>
            </div>
           </div>
        </diV>
        <div>تغییرات:&nbsp;<div :class="{change:true,red:crypto.last24HourChange<0,green:crypto.last24HourChange>0}"> {{$toLocal(Math.abs(crypto.last24HourChange),2)}}{{(crypto.last24HourChange>0)?'+':'-'}} </div></div>
        <div>بیشترین:&nbsp;<div  class="opac"> {{$toLocal(crypto.maxPrice,0)}} {{dict[tradeTo]}}</div></div>
        <div>کمترین:&nbsp;<div  class="opac"> {{$toLocal(crypto.minPrice,0)}} {{dict[tradeTo]}}</div></div>
        <div>آخرین:&nbsp;<div  class="opac"> {{$toLocal(crypto.lastPrice,0)}} {{dict[tradeTo]}}</div></div>
        <div>حجم:&nbsp;<div class="opac"> {{$toLocal(crypto.last24HourVolume,0)}} {{dict[tradeTo]}}</div></div>

    </div>
</template>
<script>
import SwitchComp from '../../../public/SwitchComp'
import {
    tradeTo,
    tradeFrom,
} from "@/library/reuseableFunction";
export default {
    name:'TradeBar',
    components:{
        SwitchComp
    },
       computed: {
        tradeTo,
        tradeFrom
    },
    props:{
        cryptos:Array,
        crypto:Object
    },
    
   created(){
     document.addEventListener('click',(e)=>{
      if(!e.target.closest('.dropdown, .cryptoTitle')){
             this.dropShow=false
      }
     })
  },
    data(){
        return{
          dict:{"TETHER":'تتر','TOMAN':'تومان'},
          baseCrypto:"TETHER",
          dropShow:false,
          choosed:"TETHER"
          


        }
    },
    methods:{
        nameGetter(coin){
            return this.$coins[coin].code
        }
    }
}

</script>
<style scoped>

.bar > *{
    display: flex;
    align-items: center;
}

.drop-open {
  transform: rotate(180deg);
}
.bar{ 
    z-index: 5;
    font-size: clamp(13px,1vw,14px);
    flex-wrap: wrap;
    width: 100%;
    column-gap: 5px;
    row-gap: 3px;
    position:relative;
    justify-content: space-around;
    align-items: center;
    min-height:38px;
    display: flex;
    color: #EFEFEF;
    background: rgba(136, 136, 136, 0.05);
    backdrop-filter: blur(15px);
    border-radius: 2px;

}

.cryptoTitle{
    font-size: clamp(13px,1vw,16px);
    cursor: pointer;
    column-gap: 8px;
    font-weight: bold;
}

.cryptoIcon{
     height: 25px;
     width: auto;
}
.dropItem{
    display: flex;
    cursor: pointer;
    justify-content: flex-start;
    align-items:center;
    
}

.green{
    background: rgba(11, 135, 16, 0.1);
    color:rgba(11, 135, 16, 1);

}
.red{
    background: rgba(214, 43, 43, 0.1);
    color:rgba(214, 43, 43, 1); ;

}
.opac{
    opacity: 0.7;
    font-size:clamp(11px,1vw,12px) ;
}
.symbol{
    opacity: 0.7;
    font-weight: normal;

}
.items{
    display: flex;
    padding: 10px;
    max-height:154px;
    width: 100%;
    overflow-y: auto;
    flex-direction: column;
    align-items: flex-start;
}

.dropdown{
    width: 100%;
    border-radius: 2px;
    max-width: 208px;
    right: 30px;
    top: 38px;
    display: flex;
    position: absolute;
    flex-direction: column;
    color: white;
    font-size: clamp(12px,1vw,14px);
 background: #252A3B;
}
</style>
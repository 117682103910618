<template>
        <div class="page">
            <div   v-if="$route.name == 'Home'" class="component">
                <Home  class="home" />
                </div>
             <div  v-if="$route.path.includes('/Auth')" class=" authpage" >
                   <div class="auth-bg"></div>
                    <Auth  />
            </div>
             <div v-if="!$route.path.includes('/Auth') && $route.name!='Home'" class="component">
                    <div class="content-bg"></div>
                    <Terms  class="content" v-if="$route.name == 'PrivacyPolicy'" />
                    <Comments  class="content" v-if="$route.name == 'Comments'" />
                    <Commition  class="content" v-if="$route.name == 'Commition'" />
                    <FAQ  class="content" v-if="$route.name == 'FAQ'" />
                    <ContactUs  class="content" v-if="$route.name == 'ContactUs'" />
                    <Markets  class="content" v-if="$route.name == 'Markets'" />
                    <AboutUs  class="content" v-if="$route.name == 'AboutUs'" />
            </div>
             <Footer class="footer" v-if="!$route.path.includes('/Auth')"/>
             <div class="chat-circle"><img class="chat-icon" src="../assets/auth/chat.svg"/></div>
           
    </div>
       
  
</template>

<script>
import Comments from './Comments'
import Commition from './Commition'
import ContactUs from './ContactUs'
import Terms from './Terms'
import Home from './HomePage/Home'
import FAQ from './FAQ'
import Markets from './Markets'
import AboutUs from './AboutUs'
import Footer from './public/Footer'
import Auth from './auth/Auth'




export default {
  name: 'Vista',
  components:{
      Home,Comments,Commition,Terms,FAQ,ContactUs,Footer,Markets,AboutUs,Auth

  }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.footer{
    z-index: 10;
    bottom: 0px;
}

.auth-bg{
    content: "";
    background-image: url('../assets/auth/authBg.png');
    background-repeat: no-repeat;
    position: fixed;  
    width: 100%;
   
    min-height:100%;
    
}

.c{
    display: none;
}

.content{
      
      width: 85%;
      z-index:2;
      height: 100%;
      margin-top:clamp(50px,7vw,100px);
      position: relative;
   
       
}

.authpage{
    display: flex;
    position: relative;
    overflow-x: hidden;
    justify-content: center;
    min-height: calc(100vh - 52px);
    flex-grow: 1;
     width: 100%;
 
}

.content-bg{
    content: "";

    background-image: url('../assets/auth/bg.png');
    background-repeat: no-repeat;
    position: absolute;
    z-index: 0;
    margin-left: 50px;
    width: 100%;
    height: 100%;
    
}
.chat-icon{
    width: 65%;
}
.chat-circle{
    position: fixed;
    z-index: 10;
    right:clamp(18px,20vw,26px);
    bottom:clamp(18px,20vw,26px);
    height: clamp(50px,6vw,70px);
    width: clamp(50px,6vw,70px);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(258.51deg, #FF951F -13.62%, #F7BD68 62.17%, #FF951F 144.54%);
}
.home{
    width: 100%;
    
}
.component{
    display: flex;
    position: relative;
    overflow-x: hidden;
    justify-content: center;
  
}
.navbar{
    z-index:  1000;
    position: sticky;
    top: 0px;
   
    width: 100%;

}

</style>

<template>

  <div class="card">
    <div class="custom-header" ><div >ورود</div></div>
    <div class="body font" >
      <div class="email">
        <label>آدرس ایمیل</label>
        <input type="email" />
      </div>
      <div class="code">
        <label >کد ۶رقمی شناسایی دوعاملی گوگل</label>
        <input type="number" class="codeinput" />
      </div>
      <button class="confirmBtn">تایید</button>
    </div>
  </div>
  
</template>

<script>
export default {
  name: "GoogleAuth",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.codeinput > input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input{
    display: block;
    margin-top:4px;
    margin-bottom:12px;
    background: rgba(255, 255, 255, 0.05);
border-radius: 2px;
color:white;
height:clamp(33px,5vw,41px);
width: 100%;
border-style: hidden;
}

input:focus {
  outline: none !important;
  border: 1px solid #f29a39;
}

.confirmBtn{
    margin-top:12px;
    height: 41px;
   
    font-weight: 500;
   background: #F29A39;
border-radius: 2px;
width: 100%;
    border-style: hidden;
  
font-style: normal;
font-weight: normal;
}
.codeinput > input[type="number"] {
  -moz-appearance: textfield;
}


.body{
    padding: 24px;
}

.card {
    text-align: right;
    margin:auto;
    font-weight: 400;
    height:306px;
    margin-top:150px;
    width: min(432px,95%);
    color: white;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(15px);
  border-radius: 2px;
  
font-style: normal;
font-weight: normal;
}

.font{
  
    font-size: clamp(13px, 1.7vw, 16px); 

}
</style>

<template>
  <div class="settings">
   <verify-method/>
   <change-password/>
  </div>
</template>

<script>
import ChangePassword from './ChangePassword'
import VerifyMethod from './VerifyMethod'
export default {
  name: "ُSettings",
  components:{
    VerifyMethod,
    ChangePassword
  },
  data() {
    return {
     
    };
  },
 
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

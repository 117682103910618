<template>
  <div class="settings">
    <div class="two-step-verify">
      <CardHeader>شناسایی دوعاملی</CardHeader>
      <div class="body">
        <p class="describe">
          تایید هویت دو مرحله ای جهت ورود به حساب کاربری و درخواست برداشت.
        </p>
        <div class="method-switch">
          <div v-for="(choice,index) in choices" :key="index" class="input-div" @click="showModal(choice.value)">
           <div class="outer-circle">
             <div class="inner-circle">
               <div :class="{circle:choice.value==twoStep}"></div>
             </div>
           </div>
            <div for="email">{{choice.label}}</div>
          </div >
        
      
        </div>
      </div>
    </div>

 
    
    <PhoneVerify v-if="choosed=='SMS'"  @submit="submitted($event)"  :mobile="mobile" @modalToggle="value => choosed=value"/>
    <EmailVerify v-if="choosed=='EMAIL'"  @submit="submitted($event)" :email="email"  @modalToggle="value => choosed=value"/>
    <GoogleVerify v-if="choosed=='GOOGLE_AUTHENTICATOR'"  @submit="submitted($event)" :qrCode="qrCode" @modalToggle="value => choosed=value"/>
  </div>
</template>

<script>
import PhoneVerify from '../../modals/PhoneVerify'
import EmailVerify from '../../modals/EmailVerify'
import GoogleVerify from '../../modals/GoogleVerify'
export default {
  name: "ُSettings",
  components:{
    GoogleVerify,
    EmailVerify,
    PhoneVerify
  },
  data() {
    return {
      twoStep: this.state.towStepAuthenticationType,
      qrCode: '',
      qrLink: '',
      email:  this.state.userInfo.email,
      mobile: '',
      completedText:'',
      choosed:'',
      choices:[
        {label:"ایمیل",value:"EMAIL"},
        {label:"پیامک",value:"SMS"},
        {label:"شناسایی دوعاملی گوگل",value:"GOOGLE_AUTHENTICATOR"}
      ]
    }
  },
    methods: {
       
       
        async getTwoStepAuth() {
            this.twoStep = this.state.userInfo.towStepAuthenticationType
        },
        async showModal(methodType) {
            this.choosed=methodType
            this.state.loading = true
            const res = await this.$axios.post(`/users/send-verification-code?twoStepAuthorization=${this.choosed}`).catch(() => {
                this.getTwoStepAuth()
            })
            if (res) {
                this.mobile = res.baseDTO.starTwoStepAuthentication || ''
                this.qrCode = res.baseDTO.file || ''
                this.qrLink = res.baseDTO.qrCodeLink || ''
                console.log(res)
            }
            this.getTwoStepAuth()
        },
        async submitted(e){
            this.completedText = e;
            await this.$error('', e, 'success')
            await this.$getuserInfo()
            this.getTwoStepAuth()
            
        },
    },
    mounted() {
        this.getTwoStepAuth()
    }
 
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label{
  padding: 0px 0px 5px 0px;
  font-size: clamp(13px,1.3vw,16px);
}
.input-div {
 
 font-size: clamp(12px,1vw,14px);
  white-space:nowrap;
  cursor: pointer;
 
  display: flex;
  align-items: center;
  column-gap:8px;
}
.outer-circle{
  border-radius: 50%;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  min-width: 13px;
  min-height:13px;
  max-width: 13px;
  max-height:13px;

background-image:linear-gradient(89.43deg, #8F5D36 0.33%, #CD8F57 27.7%, #DCA15F 49.09%, #CD8F57 74.6%, #9D663E 99.35%);
}

.inner-circle{
  border-radius:inherit;
  display: flex;
  justify-content: center;
  align-items: center;
   background: rgba(37, 42, 59, 0.8);

   min-width: 11px;
  min-height:11px;
  max-width: 11px;
  max-height:11px;
  
 
}
.circle{
  border-radius: inherit;
  content: '';
  min-width: 9px;
  min-height:9px;
 
background: linear-gradient(89.43deg, #8F5D36 0.33%, #CD8F57 27.7%, #DCA15F 49.09%, #CD8F57 74.6%, #9D663E 99.35%);
}
.dataField {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-width: 200px;
  flex: 1;
  justify-content: center;
}
.jk {
  display: flex;
}

.two-step-verify {
 
  background: rgba(136, 136, 136, 0.05);
  backdrop-filter: blur(15px);
  border-radius: 2px;
  color: white;
}

.btn {
  height: 41px;
  padding-left: 40px;
  padding-right: 40px;

  background: linear-gradient(258.51deg, #FF951F -13.62%, #F7BD68 62.17%, #FF951F 144.54%);
  border-radius: 2px;
}

.method-switch {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 54px;
  row-gap: 8px;
  font-size: 14px;
  font-weight: 400;
}

.two-step-verify .body {
  text-align: right;
  padding: 16px;
}


.input {
  display: flex;
  align-items: center;

  position: relative;
}

.describe{
  
  font-size:clamp(12px,1vw,14px) ;
  flex-direction: column;
 
}
</style>
<template>
<div class="drop-container">
  <div @click="dropOpen=!dropOpen" class="dropdown" >
    <div class="drop-title">
      <!-- <input v-model="choice.label" :disabled="true"/> -->
      <!-- <inline-svg v-if="type=='search'" class="icon" :src="require('../../assets/icons/search.svg')"/> -->
      {{choice.label}}
      <inline-svg :class="{icon:true,'drop-open':dropOpen}" @click="dropOpen=!dropOpen" :src="require('../../../assets/icons/arrowdown.svg')"/>
    </div>
    <div class="items" :style="{height:(dropOpen)?filterItems().length*32+'px':'0px'}">
      <div class="field" v-for="(item,index) in filterItems()" :key="index" @click="choose(item)">
          {{item.label}}
      </div>
    </div>
   
  </div>

</div>
</template>

<script>
export default {
  name: 'DropDown',
  props: {
    placeholder: String,
    type:String,
    items:Array,
    default:String
  },
  data(){
    return{
      dropOpen:false,
      choice:this.items[0],
    }
  },
   created(){
      document.addEventListener('click',(e)=>{
        if(!e.target.closest('.drop-title')){
              this.dropOpen=false
        }
     })
  },
  methods:{
      // filterItems(){
      //     return this.items.filter(item=>{
      //         if(item.label.includes(this.choice.label) || item.value.includes(this.choice.label)){
      //             return item
      //         }
      //     })

      // }, 
       filterItems(){
          return this.items.filter(item=>{
              if(item.value!=this.choice.value){
                  return item
              }
          })

      },
      choose(item){
          this.dropOpen=false;
          this.choice=JSON.parse(JSON.stringify(item))
          this.$emit('choose',item)
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dropdown{
    position:absolute;
    white-space: nowrap!important;;
    padding: 0px 16px;
    border-radius: 2px;
    background: #1E232E;
    z-index: 3;
    cursor: pointer;
    border: 1px solid rgba(246, 189, 104, 1);
}

.drop-open{
    transform: rotate(180deg);
}
.items{
    height:0px;
    transition: height 0.8s;
    cursor: pointer;
    background: #1E232E;
    max-height: 140px;
    overflow-y:scroll ;
}

.drop-container{
    position: relative;
    max-height: 32px;
    height: 32px;
    flex: 1;
    z-index: 3;
    flex-basis: 80px;
    min-width: 100px;
    color: #EFEFEF;
}
.field{
    height:32px;
    font-size:clamp(11px,1.2vw,12px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 6px;
  
}


.drop-title{
    height:32px;
    font-size:clamp(11px,1.2vw,12px);
    display: flex;

    align-items: center;
    justify-content: space-between;
    column-gap: 11px;
  
}

.icon{
    cursor: pointer;

}

 input{
    padding: 0px!important;
    height:100%;
    width: 100%;
    font-size: clamp(13px,1.4vw,14px);
 }
</style>

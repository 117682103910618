<template>
<div class="auth-pages" >
<SignUp v-if="$route.name=='SignUp'"/>
<SignUpOtpEmail v-if="$route.name=='SignUpOtp'"/>
<Login v-if="$route.name=='Login'"/>
<GoogleAuth v-if="$route.name=='GoogleAuth'"/>
<ChangePass v-if="$route.name=='ChangePass'"/>
<TwoStepVerify v-if="$route.name=='TwoStepVerify'"/>
<ForgetPass v-if="$route.name=='ForgetPass'"/>
<ResetPassOtp v-if="$route.name=='ResetPassOtp'"/>
<Authentication v-if="$route.name == 'Registeration'" :register="true" /> 
</div>




</template>
<script>
import TwoStepVerify from './TwoStepVerify'
import ChangePass from './forgetpass/ChangePass'
import ForgetPass from './forgetpass/ForgetPass'
import Login from './login/Login'
import GoogleAuth from './login/GoogleAuth'
import SignUpOtpEmail from './signup/SignUpOtpEmail'
import SignUp from './signup/SignUp'
import Authentication from '../panel/Authentication/Authentication'
export default {
  name: 'Auth',
  components:{
      ChangePass,ForgetPass,GoogleAuth,Login
      ,SignUp,SignUpOtpEmail,Authentication,TwoStepVerify
  }
  
}


</script>

<style scoped>


.auth-pages{
      width: 85%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index:2;
    
}
</style>
<template>
  <div class="card"  >
    <slot name="header"></slot>
    <div class="card-body"><slot name="body"></slot></div>
    <slot name="footer"> </slot>
</div>
</template>

<script>



export default {
  name: 'Card',
  components:{},
  data(){
    return {
      choosed:"VISTA"
    }

  },
  props:{
    cardWidth:String,
   
  },
  methods:{
    log(){
      console.log(this.cardWidth)
    }
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.text{
  color: black;
}
.card-body{
  width: 100%;
  height: 100%;
  background: rgba(136, 136, 136, 0.05);
/* BgBlur */

backdrop-filter: blur(15px);
   text-align: right;
    padding:15px 12px;
}


.card{
    border-radius: 2px;
    color: black;
    width: 90%;
   
    flex-grow: 1;

    
}

.card-body > div{
  width: 100%;
}

</style>

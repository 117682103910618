<template>
<div class="header">

    <div class="h1">ویستا محیطی امن و مطمئن برای معاملات شما</div>
    <div class="h2">انجام معاملات خرید و فروش خود را به ما بسپارید</div>

    <div class="row1">
        <div :class="{ active: dealType=='BUY', btn: dealType!='BUY' }" v-on:click="dealType='BUY'"> خرید</div>
        <div :class="{ active: dealType=='SELL', btn: dealType!='SELL' }" v-on:click="dealType='SELL'"> فروش</div>
    </div>
    <div class="traderequest">
        <div><label>انتخاب ارز</label>
            <!-- <div class="crypto-input"><img class="arrow" src="../../assets/auth/yellow-arrow.png" /><input :value='$coins[coin].persianName' disabled='true'/></div> -->
            <div class="drop-container">
                <div  class="dropdown">
                    <div class="drop-title" @click="dropOpen=!dropOpen">
                        <!-- <input v-model="choice.label" :disabled="true"/> -->
                        <!-- <inline-svg v-if="type=='search'" class="icon" :src="require('../../assets/icons/search.svg')"/> -->
                        {{choice.label}}
                        <img :class="{icon:true}" @click="dropOpen=!dropOpen" :src="require('../../assets/auth/yellow-arrow.png')" />
                    </div>
                    <div class="items" :style="{height:(dropOpen)?filterItems().length*32+'px':'0px'}">
                        <div class="field" v-for="(item,index) in filterItems()" :key="index" @click="choose(item)">
                            {{item.label}}
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <div><label>مقدار </label><input v-model="amount" @input="amount=$toLocal(amount,$decimal[coin.destCoinSymbol])" /></div>
        <div><label>قیمت واحد </label><input :disabled="true" :value="$toLocal(unitPrice,0)" /></div>
        <div><label>مجموع</label><input :value="$toLocal($S2N(amount)*$S2N(unitPrice),0)" :disabled="true"/></div>
    </div>
    <div class="tradebtns">
        <button class="buyrequest"  @click="state.userInfo.authorized?'':$router.push('/Auth/Login')">درخواست {{dealType=='BUY'?'خرید':'فروش'}}</button>
    </div>

</div>
</template>

<script>
import {
    Loop,

} from "@/library/reuseableFunction";

export default {
    name: 'Header',
    data() {
        return {
            dealType: 'BUY',
            dropOpen: false,
            choice: {
                label: 'بیتکوین',
                value: 'BITCOIN'
            },
            cryptos: [],
            coin: {},
            amount: 0
        }
    },
    computed: {
        unitPrice() {
            if (this.coin)
                return this.dealType == 'BUY' ? this.coin.buyPrice : this.coin.sellPrice
            else
                return 0
        },
        coins() {

            return Object.entries(this.$coins).filter(coin => coin[1].code && coin[0] != 'TOMAN').map(coin => {
                return {
                    label: coin[1].persianName,
                    value: coin[0]
                }
            })
        },
    },
    methods: {
        // filterItems(){
        //     return this.items.filter(item=>{
        //         if(item.label.includes(this.choice.label) || item.value.includes(this.choice.label)){
        //             return item
        //         }
        //     })

        // }, 
        filterItems() {
            return this.coins.filter(item => {
                if (item.value != this.choice.value) {
                    return item
                }
            })

        },
        async getCoinsPrice() {
            await this.$axios.get('coins/exchange-price/usd', {
                params: {
                    includeWeeklyChange: false
                }
            }).then(response => this.cryptos = response)
            this.coin = this.cryptos.find(coin => coin.destCoinSymbol.includes(this.choice.value))


        },
        choose(item) {
            this.dropOpen = false;
            this.choice = JSON.parse(JSON.stringify(item))
            this.coin = this.cryptos.find(coin => coin.destCoinSymbol.includes(this.choice.value))

        }
    },
    mounted() {
        if (this.state.loop) {
            this.loop = new Loop(this.getCoinsPrice, this.state.time)
            this.loop.start()
        } else {
            this.getCoinsPrice()
        }

    },
      beforeDestroy(){
      this.loop.stop()
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.dropdown {
    position: absolute;
    white-space: nowrap !important;
    width: 100%;
    border-radius: 2px;
    backdrop-filter: blur(15px);

    background: rgba(136, 136, 136, 0.05);
    z-index: 3;
    cursor: pointer;
}

.drop-open {
    transform: rotate(180deg);
}

.items {
    height: 0px;
    transition: height 0.6s ease-out;
    cursor: pointer;
    padding: 0px 16px;

    background: #252A3B;
    max-height: 100px;
    overflow-y: scroll;
}
label{
    margin-bottom: 6px;
}
.drop-container {
    position: relative;
    max-height: 41px;
    height: 41px;
    flex: 1;

    min-width: 110px;
    z-index: 3;
    flex-basis: 80px;
    color: #EFEFEF;
}

.field {
    height: 32px;
    font-size: clamp(11px, 1.2vw, 12px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 6px;

}

.drop-title {
    height: 41px;
    font-size: clamp(11px, 1.2vw, 12px);
    background: rgba(255, 255, 255, 0.05);

    display: flex;
    padding: 0px 16px;
    align-items: center;
    justify-content: space-between;
    column-gap: 11px;

}

.icon {
    cursor: pointer;

}

.active {
    opacity: 1 !important;
    background: linear-gradient(258.51deg, #FF951F -13.62%, #F7BD68 62.17%, #FF951F 144.54%);
    color: black;
    flex: 1;
    display: flex;
    align-items: center;
    border-radius: 2px;
    justify-content: center;

}

.row1 {
    color: #EFEFEF;
    display: flex;
    cursor: pointer;
    height: 41px;
    margin-top: 41px;
    max-width: 180px;
    /* background: rgba(136, 136, 136, 0.05); */
    background: rgba(30, 35, 46, 0.6);
    border-radius: 2px;
}

.btn {
    display: flex;
    flex: 1;
    justify-content: center;
    border-radius: 2px;
    align-items: center;
    vertical-align: middle;
    background: linear-gradient(258.51deg, #FF951F -13.62%, #F7BD68 62.17%, #FF951F 144.54%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    opacity: 0.7;
    font-style: normal;
}

.traderequest {
    display: flex;
    row-gap: 15px;
    flex-wrap: wrap;
    column-gap: 16px;
    margin-top: 21px;
    justify-content: center;
}

input {
    height: 41px;
    background: rgba(255, 255, 255, 0.05);
    
    backdrop-filter: blur(15px);

    border-radius: 2px;
    flex-grow: 1;
    color: white;
    width: 100%;

}

.traderequest>div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.h1 {
    font-size: clamp(21px, 1.8vw, 24px);
    font-weight: 500;
}

.h2 {
    font-size: clamp(17px, 1.4vw, 18px);
    font-weight: 500;
    margin-top: 16px;
}

.crypto-input {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: flex-end;
}

.arrow {
    position: absolute;
    padding-left: 10px;
    z-index: 1;

}

.header {
    text-align: right;
    display: flex;
    width: 100%;
    max-width: 791px;
    flex-direction: column;
    /* align-items: center; */
}

.tradebtns {
    display: flex;
    flex: 1;
    margin: auto;
    justify-content: center;
    column-gap: 18px;
    margin-top: 32px;
    max-width: 400px;
}

.header>* {
    width: 100%;
}

.tradebtns>button {
    flex-grow: 1;
    height: 41px;
    border-radius: 2px;
}

.buyrequest {
    background: linear-gradient(258.51deg, #FF951F -13.62%, #F7BD68 62.17%, #FF951F 144.54%);
    max-width: 200px;
}

.sellrequest {

    color: #F7BD68;
    background: rgba(136, 136, 136, 0.05);

}
</style>

<template>
<div class="navbar" :class="{'panel-navbar':$route.path.includes('Panel'),'public-navbar':!$route.path.includes('Panel')}">
    <img class="site-icon" @click="$router.push('/')" src="../../assets/auth/site-logo.png" />

    <i id="sidebar-burger" @click="sideOpen=!sideOpen" class="fas fa-bars fa-lg barIcon"></i>

    <!-- panel nav links -->
    <div v-if="$route.path.includes('Panel')" class="links">
        <a href="/Markets" @click.prevent="$router.push('/Markets')">
            <li class="link"> بازارها</li>
        </a>
        <li class="link  resp" @click="profileDrop=false;dealsDrop=!dealsDrop"> معامله
            <img :class="{dropopen:dealsDrop}" :src="require('../../assets/panel-navbar/arrowdown.svg')" />
        </li>
        <div class="dealDrop" v-if="dealsDrop">
            <div class="dropitem" v-for="(item,index) in dealItems" :key="index" @click="$router.push('/Panel/'+item.name);dealsDrop=false"> <img :src="require('../../assets/sidebar/'+item.icon+'.svg')" />{{item.label}}</div>
        </div>

        <a href="/Panel/History/Trades" @click.prevent="$router.push('/Panel/History/Orders')">
            <li class="link resp"> تاریخچه</li>
        </a>
    </div>

    <!-- public nav links -->
    <div v-else class="links">
        <a v-for="(link, index) in publicLinks" :class="{link:true,active:($route.name==link.name)}" :key="index" :href="'/'+link.name" @click.prevent="$router.push('/'+link.name)">{{ link.title }}</a>
    </div>

    <!-- panel end links -->
    <div v-if="state.token" class="end-links">
        <div v-if="$route.path.includes('Panel')" class="user-level resp" @click="modalType='levels'">سطح یک کاربری</div>
        <div v-if="$route.path.includes('Panel')" class="user-lvl-number" @click="modalType='levels'">{{userLevels[state.userInfo.userAccountLevel]}}</div>
        <img v-if="$route.path.includes('Panel')" class="resp" :src="require('../../assets/panel-navbar/support.svg')" />
        <div class="link drop-container">
            <img :src="require('../../assets/panel-navbar/user.svg')"  @click="dropOpen=!dropOpen" />
            <div class="username resp" v-if="state.userInfo.authorized">{{state.userInfo.firstName+' '+state.userInfo.lastName}}</div>
            <div class="dropdown" :style="{height:dropOpen?30*(profileLinks.length+1)+'px':'0px',opacity:1}">
                <a v-for="(link,index) in profileLinks" :href="'/Panel'+link.path" @click.prevent="dropOpen=false;$router.push('/Panel'+link.path);" :key="index" class="access">
                    {{link.name}}
                </a>
                <div class="access" @click="showExitModal=true">خروج</div>

            </div>
        </div>

    </div>

    <!-- public end links -->
    <div v-else class="btns">
        <router-link to="/Auth/SignUp"> <button class="btn register-btn">ثبت نام</button></router-link>
        <router-link to="/Auth/Login"><button class="btn login-btn">ورود</button></router-link>
    </div>

    <Sidebar v-if="$route.path.includes('Panel')" id="sidebar" :style="{ width:(sideOpen)?'200px':'0px' }" class="sidebar" />

    <div v-else id="sidebar" class="sidenav" :style="{ width:(sideOpen)?'150px':'0px' }">
        <!-- <li class="closebtn" :style="{listStyle:'none'}"  @click="sideWidth='0px'">&times;</li> -->
        <div v-for="(link, index) in publicLinks" @click="$router.push('/'+link.name)" :key="index" :class="{'side-link-cotainer':true,activeSideLink:($route.name==link.name)}">
            <li class="sidelnk">{{ link.title }}</li>

        </div>

    </div>
    <UserLevels @modalToggle="value=>modalType=value" v-if="modalType=='levels'" />
    <ExitModal v-if="showExitModal" @modalToggle="showExitModal=false;dropOpen=false" />

</div>
</template>

<script>
import ExitModal from '../modals/ExitModal'

import UserLevels from '../modals/UserLevels'
import Sidebar from '../panel/Sidebar'
export default {
    name: "Navbar",
    components: {
        Sidebar,
        UserLevels,
        ExitModal
    },
    created() {
        document.addEventListener('click', (e) => {
            if (e.target != document.getElementById('sidebar') && e.target != document.getElementById('sidebar-burger')) {

                this.sideOpen = false
            }
        })
    },
    data() {
        return {
            showExitModal:false,
            dropOpen: false,
            profileLinks: [{
                    name: "داشبورد",
                    path: "/Dashboard"
                },
                {
                    name: "حساب کاربری",
                    path: "/Authentication"
                },
                {
                    name: "کیف پول",
                    path: "/Wallet"
                },
                {
                    name: "معامله",
                    path: "/AdvTrade/TOMAN/BITCOIN"
                },

            ],
            userLevels: {
                'BRONZE': 1,
                'SILVER': 2,
                'GOLD': 3,
                'PLATINUM': 4,
                'SUPERVISOR': 5,
            },
            modalType: '',
            publicLinks: [{
                    title: "بازارها",
                    name: "Markets"
                },
                {
                    title: "کارمزد",
                    name: "Commition"
                },
                {
                    title: "قوانین و مقررات",
                    name: "PrivacyPolicy"
                },
                {
                    title: "نظرات کاربران",
                    name: "Comments"
                },
                {
                    title: "درباره ویستا",
                    name: "AboutUs"
                },
                {
                    title: "تماس با ما",
                    name: "ContactUs"
                },
                {
                    title: "سوالات متداول",
                    name: "FAQ"
                },
                {
                    title: "بلاگ"
                },
            ],
            panelLinks: [{
                title: "بازارها"
            }, {
                title: "معامله"
            }, {
                title: "تاریخچه"
            }],
            sideOpen: false,
            showLevel: false,
            dealsDrop: false,
            profileDrop: false,
            dealItems: [{
                    label: "معامله پیشرفته",
                    icon: "advdeal",
                    name: 'AdvTrade/TOMAN/BITCOIN'
                },
                {
                    label: "معامله آسان",
                    icon: "ezdeal",
                    name: 'EZTrade'
                },
                {
                    label: "معامله وب مانی",
                    icon: "webmoney",
                    name: 'WebTrade'
                },
                {
                    label: "معامله پرفکت مانی",
                    icon: "perfectmoney",
                    name: 'PerfectTrade'
                }
            ]

        };
    },
};
</script>

<style scoped>
.activeSideLink {
    background: rgba(242, 154, 57, 0.8);

}

.public-navbar {
    padding: 0px clamp(30px, 6vw, 120px);
}

.site-icon {
    width: 36px;
    margin-left: 10px;
    cursor: pointer;
}

.dropdown {
    position: absolute;
    width: max(100%, 130px);
    background: #1E232E;
    top: 100%;
    display: flex;
    padding: 0px 8px;
    flex-direction: column;
    transition: height 0.5s;
    height: 0px;
    opacity: 0;
    overflow: hidden;
    box-shadow: 0px 0px 10px rgba(231, 167, 67, 0.2);
    border-radius: 4px;
    direction: rtl;

}

.drop-container {
    display: flex;
    position: relative;
    direction: ltr;

}

.access {
    color: #fcfcfc;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: clamp(13px, 1.4vw, 16px);
    height: 30px;
    min-height: 30px;
}

.access:hover {
    color: #FF951F;
}

.panel-navbar {
    padding: 0px clamp(10px, 1.8vw, 21px);
}

.navbar {
    position: sticky;
    top: 0px;
    z-index: 20;
    display: flex;
    height: 52px;
    justify-content: space-between;
    align-items: center;
    background: #1E232E;
    box-shadow: 0px 0px 10px rgba(231, 167, 67, 0.2);
    color: white;
}

.barIcon {
    margin-left: 20px;
    cursor: pointer;
}

.circle {
    font-size: 36px;
    margin-left: 12px;
    color: white;
    cursor: pointer;
}

.links {
    display: flex;
    justify-content: space-around;
    font-size: clamp(13, 1.5vw, 16);
    column-gap: clamp(10px, 1.8vw, 32px);
    align-items: center;
}


.link {
    display: flex;
    align-items: center;
    cursor: pointer;
    column-gap: 7px;
    color: rgba(255, 255, 255, 0.7);

}

.active {
    color: rgba(255, 255, 255, 1);
    ;

}

.sidenav {

    height: 100%;
    width: 250px;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: #252A3B;
    ;
    box-shadow: 0px 0px 10px rgba(231, 167, 67, 0.2);
    text-align: right;
    overflow-x: hidden;
    margin-top: 52px;
    ;
    transition: width 1s;
}

/* The navigation menu links */
.sidelnk {
    padding: 8px 0px 8px 20px;
    text-decoration: none;
    color: white;
    white-space: nowrap;
    display: block;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    list-style: none;
}

.side-link-cotainer {
    padding: 0px 6px 0px 6px;

}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
    position: absolute;
    top: 0;
    color: #F29A39;

    font-size: 36px;
    margin-left: 50px;
    cursor: pointer;
}

.register-btn {
    background: linear-gradient(258.51deg, #FF951F -13.62%, #F7BD68 62.17%, #FF951F 144.54%);
    border-radius: 2px;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 5px;
}
.login-btn {
    background: rgba(255, 255, 255, 0.05);
    color: white;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-style: hidden;

    font-style: normal;
    border: 1px solid #FF951F;
    border-radius: 2px;
}

.btns {
    justify-content: flex-start;
    align-items: center;
    display: flex;
    grid-column-start: 11;
    grid-column-end: 14;
}

/* panel van */
.end-links {
    display: flex;
    column-gap: 17px;
    flex: 1;
    list-style: none;
    align-items: center;
    color: white;
    justify-content: flex-end;
    font-style: normal;
    font-weight: 500;

}

.user-lvl-number {
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    display: none;
    align-items: center;
    justify-content: center;
    font-size: clamp(15px, 1vw, 16px);
    max-height: 30px;
    border-radius: 50%;
    border: 1px solid #F29A39;
}

.user-level {
    border: 1px solid #F29A39;
    padding: 8px;
    font-weight: 500p;
    font-size: clamp(10px, 1.3vw, 14px);
    cursor: pointer;
}

.dealDrop {
    font-size: clamp(12px, 1vw, 14px);
    margin-top: 210px;
    margin-right: 130px;
    background: #252A3B;
    z-index: 110;
    position: absolute;

    text-align: right;
    width: 176px;

    box-shadow: 0px 0px 10px rgba(231, 167, 67, 0.2);

}

.dealDrop div {
    display: flex;
    align-items: center;
    column-gap: 6px;

    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    height: 40px;
    padding: 10px;
}

.drop-container {
    position: relative;

}

.dropopen {
    transform: rotate(180deg);
}

.profileDrop {
    display: none;
    margin-top: 10px;
    margin-right: -54px;
    background: #252A3B;
    z-index: 110;
    position: absolute;
    right: 0px;
    text-align: right;
    width: 108px;
    box-shadow: 0px 0px 10px rgba(231, 167, 67, 0.2);

}

.profileDrop div {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    height: 30px;
    cursor: pointer;
    padding: 5px 10px 5px 10px;
}

.sidebar {
    height: calc(100% - 53px);
    display: none;

    position: absolute;
    /* Stay in place */
    z-index: 6;
    /* Stay on top */
    top: 52px;
    /* Stay at the top */
    right: 0;
    transition: width 0.6s;
    white-space: nowrap;
    background-color: #252A3B;
    /* Black*/
    overflow-x: hidden;

}

/* Extra large devices (large desktops, 1200px and up) */
@media (max-width: 1200px) {
    .links {
        display: none;
    }

}

@media (min-width: 1200px) {
    .barIcon {
        display: none;
    }

    .sidenav {
        display: none;
    }

}

@media(max-width:500px) {

    .resp {
        display: none;
    }

    .profileDrop {
        display: block;
    }

    .user-lvl-number {
        display: flex;
    }

}

@media(max-width:950px) {
    .sidebar {
        display: block;
    }

    .burger {
        display: block;
    }
}
</style>

<template>
<div class="modal-page"> 
<div class="modal">
<Modal @close="$emit('modalToggle','')">
    <template #header>
          شناسایی دوعاملی
    </template>
    <template #body >
           <div>
               <p class="mytext">لطفا بارکد زیر را توسط برنامه Google Authenticator گوشی خود اسکن نمایید.</p>
               <div class="barcode">
                    <img
                        width="80%"
                            :src="'data:image/png;base64,'+qrCode"
                        alt="">
               </div>
               <p class="mytext">پس از اسکن بارکد، مورد مربوط به آی تی ارز در برنامه شما ایجاد خواهد شد. برای تایید صحت فرآیند، کدی که در اپ خود مشاهده می‌کنید را در کادر زیر وارد نمایید.</p>
               <LabelInput @value='(value)=>otp.code=value' :theme=1 label="شناسایی دوعاملی گوگل "/>  
           </div>
    </template>
    <template #footer>
         <CardButton :disabled="!otp.code" @clicked="submit()">تایید کد</CardButton> 
    </template>
</Modal>
</div>
</div>
</template>

<script>
import Modal from './Modal'
export default {
  name: 'CryptoBuy',
  components:{
      Modal,
  },
  props:['qrCode']
  ,data(){
      return{
      otp:{
          code:''
      },
      }
  },
    methods : {
    
      
  
         
            async submit() {
                // const a = {
                //     'EMAIL': 'ایمیل',
                //     'SMS': 'پیامک',
                //     'GOOGLE_AUTHENTICATOR': 'Google Authenticator'
                // }
                if (this.otp.code.toString().length === 6) {
                        this.state.loading = true
                        const [res,] = await this.$http.post(`/users/change-otp?twoStepAuthorization=GOOGLE_AUTHENTICATOR&code=${this.otp.code}`)
                        if(res){
                            // this.reset()
                            this.$emit('modalToggle','')
                            this.$emit('submit', 'شناسایی دو مرحله ای شما به ' + 'Google Authenticator' + ' تغییر یافت.')
                        }
                   
                }
                
            },

  },
 
}
</script>

<style scoped>
.modal{
    max-width: 311px;
}

.barcode{
    width: 100%;
    display: flex;
    justify-content: center;
}
.mytext{
    font-size: clamp(12px,1.4vw,15px);
}
</style>
<template>
  <div class="page">
      <CardHeader>آپلود مدارک</CardHeader>
      <div class="cardbody records-upload ">
    
    <div>عکس مدارک خود را که شامل عکس سلفی ، کارت بانکی و کارت ملی است را در فیلد های مربوط به خود آپلود کنید. </div>


    <div>
      لطفا قبل از ارسال فایل احراز هویت مطالب زیر را مطالعه کنید
      فایل باید حتما طبق نمونه ارسال گردد <br> 
      حتما مدارک را همراه با برگه نوشته با متن طبق نمونه در دسـت بگـیرید <br> 
      عکس ها سلفی بدون برگه نوشته توضیحات و تاریخ تایید نخواهد شد <br> 
      برای احراز هویت ارسال عکس تنها از کارت بانکی یا شناسایی قابل قبول نیست <br> 
      صورت شما و نوشته های کارت باید در عکس سلفی واضح باشد <br> 
      مـدارک ارسـالی به صـورت اسـکن شـده قـابل قـبول نیـست و حـتما باید با موبایل عکس رفته شده باشد <br> 
      تاریخ روی برگه باید همان روز ارسال باشد  <br> 
    </div>
    <div :style="{opacity: '0.6'}">
        

    این جانب ........... با شماره ملی ....... در تاریخ ........... مدارک خود را جهت احراز هویت در سایت ادا تتر  ارسال و اعلام می کنم مدارک، حساب کاربری و اطلاعات کارت بانکی خود را در اختیار
    دیگری قرار نداده ام و متعهد می شوم از دریافت وجه و خرید ارز دیجیتال برای اشخاص دیگر تحت هر عنوانی (آگهی استخدام، کسب سود، کاریابی در منزل و ....) خودداری کنم. در غیر این صورت 
    چنانچه وجهی از سوی دیگران برای خرید به حسابم واریز شد ، متوجه هستم که ممکن است وجه واریزی سرقتی باشد و مسوولیت حقوقی و قضایی آن اعم از مجاز ات قانونی و .جبران ضرر و زیان
    شاکی بر عهده بنده خواهد بود.

    </div>
    <div><img class="icon" src='../../../assets/authentication/danger.svg'/>
    توجه : متن باید به صورت دستی نوشته شود و از تایپ کردن سیستمی خودداری نمائید. در صورت در دسترس نبودن کارت ملی برای شناسایی ، استفاده از کارت گواهینامه معتبر یا شناسنامه
    جدید بلامانع است. 
    </div>
    <div class="image-upload">
      <img src="../../../assets/panel/wrong-photo.svg"/>
      <img src="../../../assets/panel/right-photo.svg"/>
      <div class="upload-sec"><div>
        <img v-if="path" id='output-img' class="user-img" :src="path"/>
        <img v-else id='output-img' src="../../../assets/panel/upload-photo.svg"/>
        <!-- <img :src="require(path)"/>     -->

      </div><button  class="confirmBtn">آپلود عکس سلفی</button><input type="file" class="file-input"  
       @change="upload($event.target)"  required/></div>

</div>
<div>
 
</div>

</div>



    </div>
</template>

<script>
export default {
  name: 'ProofUpload',
  props:{
          data:Object
  },
   watch: {
            'data.image'() {
                if (this.data.image)
                    if (typeof this.data.image === 'string')
                        this.path = this.data.image
            }
        },
         data() {
            return {
                path: '',
                loadedpicture: '',
                hovered: '',
            }
        },
   computed: {
            readonly() {
                return ['AUTHORIZED', "INFORMATION_CHANGED"].includes(this.state.userInfo.authenticationType)
            }
        },
  methods:{
             drop(e) {
                let dt = e.dataTransfer
                this.upload(dt)
            },
            upload(evt) {
                let file = evt.files[0]
                this.data.image = file
                let fileReader = new FileReader();
                fileReader.addEventListener("loadstart", (event) => {
                    this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
                })
                fileReader.addEventListener("progress", (event) => {
                    this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
                });
                fileReader.addEventListener('load', (event) => {
                    this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
                    this.path = event.target.result
                });
                fileReader.readAsDataURL(file)
            },
            // loadFile(event){         
            //         var image = document.getElementById('output-img');
            //         this.data.image=URL.createObjectURL(event.target.files[0]);
            //         image.src = URL.createObjectURL(event.target.files[0]);
            //         document.getElementById('upload-image-sub').style.display="none";
            //         console.log(this.data.image);
            // }
      
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.image-upload{
  direction: ltr;
  display: flex;
  column-gap: 24px;
  flex-wrap: wrap;
  row-gap:20px;
  justify-content: center;
}

.user-img{
  max-width: 100%;
  height: 100%;
}
.upload-sec > button{
border-bottom-left-radius: 2px;
border-bottom-right-radius: 2px;
width: 100%;
height:41px;

}
.upload-sec > div{
  display: flex;
  align-items: flex-start;
  align-items: center;
  height:100%;
  justify-content: center;
}
.upload-sec{
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-end;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 2px;
  max-width: 360px;
  width: 100%;

}


.file-input{
  opacity: 0;
  position: absolute;
  width: 100%;
  cursor: pointer;
  height: 100%;
}
</style>

<template>
<div class="inviteFriends">
<div class="inviteLinks">
<p>
 هر فردی که با استفاده از کد دعوت و یا لینک دعوت شما ثبت نام نماید 20% درصد از کارمزد ریالی که والکس از هر معامله آن فرد دریافت می کند به شما تعلق می گیرد.
    <br>هم چنین فرد دعوت شده می تواند دو معامله با کارمزد صفر انجام دهد و دو پله هم کارمزد معاملاتی شخص کاهش پیدا می کند. 
</p>

<div class="links">
        <div>
            <label>کد دعوت</label>
            <div>
                 <span class="copyIcon"><img  src="../../assets/panel/copy.svg" @click="copyCode"/></span>
                 <div class="copyAlert" v-if="copyCodeShow">copied</div>
                <input type="text" :value="inviteData.referralCode" readonly/>
            </div>
        </div>
        <div>
            <label>لینک دعوت</label>
            <div>
                 <span  class="copyIcon"><img  src="../../assets/panel/copy.svg" @click="copyLink"/></span>
                   <div class="copyAlert" v-if="copyLinkShow">copied</div>
                <input type="text" :value="'PrefectMoney.com/SignUp?refer_code='+inviteData.referralCode" readonly/>
            </div>
        </div>
</div>

</div>
<div class="inviteDetails">
    <div>
         <img src="../../assets/panel/friendsInvite.svg"/>
         <div>
             <h1>تعداد دوستان من</h1>
             <h2>{{inviteData.countInvitees}} نفر</h2>
         </div>

    </div>
    <div>
     <img src="../../assets/panel/flower.svg"/>
       <div>
             <h1 >درآمد من</h1>
             <h2>{{inviteData.profit.toLocaleString()}} تومان</h2>

</div>
</div>
</div>
</div>


  
</template>

<script>
export default {
  name: 'InviteFriends',
  
  data(){
      return {
        link:'',
        code:'',
        profit:'',
        friendsNumber:'',
        codeAlertShow:false,
        linkAlertShow:false,
        inviteData:{countInvitees:'',profit:'',referralCode:''},
        copyLinkShow:false,
        copyCodeShow:false,
      }
  },
  methods:{
      copyLink(){
           navigator.clipboard.writeText('PrefectMoney.com/SignUp?refer_code='+this.inviteData.referralCode)
           this.copyLinkShow=true;
         setTimeout(()=>{this.copyLinkShow=false;},3000)



      },
      copyCode(){
          navigator.clipboard.writeText(this.inviteData.referralCode)
          this.copyCodeShow=true;
          setTimeout(()=>{this.copyCodeShow=false} ,3000)
      },
       async getData(){
      
       const res = await this.$axios.get('/users/invites');
       this.inviteData=res.baseDTO;
       this.link='bodex.com/SignUp?refer_code='+this.inviteData.referralCode
       this.code=this.inviteData.referralCode
       
       console.log(res)
  }
  },
  mounted(){
      this.getData()
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.inviteFriends{
    display: flex;
    flex-direction:column;
    flex-wrap: wrap;
    padding:16px;
    color:white;
}

.copyAlert{
    padding: 3px 10px 3px 10px;
    background: rgb(56, 56, 56);
    border-radius: 2px;
    color: white;font-size: 13px;
    position: absolute;
    margin-left: 20px;
    margin-bottom: 20px;
}
.inviteLinks{
    display: flex;
    flex-direction: column;
    padding: 16px;
    background: rgba(136, 136, 136, 0.05);
    border-radius: 2px;
    font-size: clamp(12px,1.3vw,14px);
    text-align: right;
    font-weight:400;
}
.inviteDetails{
   
 display: flex;
 margin-top: 8px;
 column-gap: 8px;
 row-gap:8px;
 flex-wrap: wrap;
}
.links > div > div{
    display: flex;
    align-items: center;
    position: relative;
    
}

.links > div{
   font-size: clamp(13px,1.5vw,16px);
    justify-content: center;
    flex: 1;
    justify-content: left;
}
.copyIcon{
  
   background: rgba(255, 255, 255, 0.05);
   
   
    height: 41px;
    display: flex;
   
    width: 32px;
 
    align-items: center;
    justify-content: flex-end;
   
    cursor: pointer;
}
.inviteDetails > div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap:10px ;
    column-gap: 15px;;
      padding:16px;
      align-items: center;
    flex-grow:1;
     background: rgba(136, 136, 136, 0.05);
border-radius: 2px;
}

.inviteDetails > div > div{
   
    justify-content: center;
    flex:1;
    font-weight: 500;
}


.inviteDetails > div > div > h1{
    font-size: clamp(16px,2vw,20px);
    white-space: nowrap;
}


.inviteDetails > div > div > h2{
    font-size: clamp(18px,2.4vw,24px);
    white-space: nowrap;
}
.links{
 display:flex;
 justify-content: center;
 column-gap: 10px;
 flex-wrap: wrap;
 row-gap: 15px;
 padding: clamp(10px,4vw,48px);
}

input{
    height:41px;
   background: rgba(136, 136, 136, 0.05);
border-radius: 2px;
color:white;
width: 100%;
max-width: 344px;
min-width: 150px;
direction: ltr;


}


</style>

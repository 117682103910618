<template>
<div>

    <div class="filters">
        <div class="right-filter">
            <DropFilter class="f-2" @choose="(value)=>{exchangeDealType=value.value}" :items="[{label:'نوع معامله',value:''},{label:'خرید',value:'BUY'},{label:'فروش',value:'SELL'}]" />
            <DropFilter @choose="(value)=>{coin1=value.value}" :items="[{label:'رمزارز',value:''},...coins]" />

        </div>

    </div>
    <div class="table-container">
        <div class="table-title">تاریخچه سفارشات آسان </div>
    <!-- <perfect-scrollbar @ps-y-reach-end="getLastOrders()" ref="scroll"> -->

        <table>
            <tr class="table-header">
                <td >نوع سفارش</td>
                <td>رمزارز </td>
                <td class="resp-3">قیمت واحد<span class="tag">(تومان)</span></td>
                <td> مقدار </td>
                <td class="resp-2">قیمت کل<span class="tag">(تومان)</span></td>
                <td class="resp-1">زمان </td>
                <!-- <td class="resp-2">پیگیری </td> -->
                <td class="resp-3">وضعیت </td>
            </tr>
            <tbody>
                <!-- <tr class="table-row" v-for="(order,index) in filterOrders()" :key="index">
                <td :class="{'resp-3':true,'depsoit-buy':order.tradeType=='BUY','withdraw-sell':order.tradeType=='SELL'}">{{dict[order.tradeType]}}</td>
                <td>
                    <div class="coin-title">
                        <span>{{$coinLabel[order.coinName]}}</span>
                    </div>
                </td>
                <td class="resp-3">{{order.unitPrice.toLocaleString()}}</td>
                <td dir="ltr">{{order.amount}}&nbsp;{{$coinUnit[order.coinName]}} </td>
                <td class="resp-3">{{order.totalPrice.toLocaleString()}}</td>
                <td class="resp-1">{{order.date}}</td>
                <td class="resp-2">{{order.check}}</td>
                <td  class="resp-3" v-if="order.condition=='SUCCESS'"><img src="../../../assets/icons/success.svg"/></td>
                <td  class="resp-3" v-if="order.condition=='FAIL'"><img src="../../../assets/icons/fail.svg"/></td>
            </tr> -->
                <tr class="table-row" v-for="(order,index) in filterExchangeOrders(exchangeDealType)" :key="index">
                    <td :style="{color:(order.orderType.includes('BUY'))?'#0B8710':'#D62B2B'}">
                        {{dict[order.orderType.split('_')[0]]+' - '+dict[order.orderType.split('_')[1]]}}
                    </td>
                    <td>{{$coins[order.marketType.replace('_TOMAN','')].persianName || $coins[order.marketType.replace('_TETHER','')].persianName}}</td>
                    <td class="resp-3">{{$toLocal(order.unitPrice,0)}}</td>
                    <td dir='ltr'>{{order.amount}}&nbsp;{{$coins[order.marketType.replace('_TOMAN','')].code || $coins[order.marketType.replace('_TETHER','')].code}}</td>
                    <td class="resp-2">{{$toLocal(order.wholePrice,0)}}</td>
                    <td class="resp-1">{{$G2J(order.createdAtDateTime)}}</td>
                    <td align="center" class="resp-3">
                        <!-- <span class="condition" v-if='isActive'>{{order.executedPercent}}%<img src="../../../assets/auth/trash.svg" /></span> -->
                        <span class="condition"><img :src="require('../../../assets/icons/'+statuses[order.orderStatus]+'.svg')"></span>
                        <!-- <span>{{order.orderStatus}}</span> -->
                    </td>
                </tr>
            </tbody>
        </table>
    <!-- </perfect-scrollbar > -->

    </div>

    <div class="filters">
        <div class="right-filter">
            <DropFilter class="f-1" @choose="(value)=>{marketType=value.value}" :items="marketTypes" />
            <DropFilter class="f-2" @choose="(value)=>{P2PDealType=value.value}" :items="[{label:'نوع معامله',value:''},{label:'خرید',value:'BUY'},{label:'فروش',value:'SELL'}]" />
            <DropFilter @choose="(value)=>{coin2=value.value}" :items="[{label:'رمزارز',value:''},...coins]" />

        </div>

    </div>
    <div class="table-container">
        <div class="table-title">تاریخچه سفارشات پیشرفته </div>
        <table>
            <tr class="table-header">
                <td>نوع سفارش</td>
                <td>رمزارز</td>
                <td class="resp-3">قیمت واحد<span class="tag">(تومان)</span></td>
                <td> مقدار</td>
                <td class="resp-2">قیمت کل<span class="tag">(تومان)</span></td>
                <td class="resp-1">زمان</td>
                <td class="resp-3">وضعیت</td>
            </tr>
            <tbody>
                <!-- <tr class="table-row" v-for="(order,index) in filterOrders('P2P',P2PIsBuy)" :key="index">
                    <td :class="{'depsoit-buy':order.tradeType=='BUY','withdraw-sell':order.tradeType=='SELL'}">{{dict[order.tradeType]}}&nbsp;-&nbsp;{{dict[order.marketType]}}</td>
                    <td>
                        <div class="coin-title">
                            <span>{{$coinLabel[order.coinName]}}</span>
                        </div>
                    </td>
                    <td class="resp-3">{{order.unitPrice.toLocaleString()}} </td>
                    <td dir="ltr">{{order.amount}}&nbsp;{{$coinUnit[order.coinName]}} </td>
                    <td class="resp-3">{{order.totalPrice.toLocaleString()}}</td>
                    <td class="resp-1">{{order.commission}}</td>
                    <td class="resp-1">{{order.date}}</td>
                    <td class="resp-3" v-if="order.condition=='SUCCESS'"><img src="../../../assets/icons/success.svg" /></td>
                    <td class="resp-3" v-if="order.condition=='FAIL'"><img src="../../../assets/icons/fail.svg" /></td>
                </tr> -->
                <tr class="table-row" v-for="(order,index) in filterP2POrders(marketType,P2PDealType)" :key="index">
                    <td :style="{color:(order.orderType.includes('BUY'))?'#0B8710':'#D62B2B'}">
                        {{dict[order.orderType.split('_')[0]]+' - '+dict[order.orderType.split('_')[1]]}}
                    </td>
                    <td>{{$coins[order.marketType.replace('_TOMAN','')].persianName || $coins[order.marketType.replace('_TETHER','')].persianName}}</td>
                    <td  class="resp-3">{{$toLocal(order.unitPrice,0)}}</td>
                    <td dir='ltr'>{{order.amount}}&nbsp;{{$coins[order.marketType.replace('_TOMAN','')].code || $coins[order.marketType.replace('_TETHER','')].code}}</td>
                    <td class="resp-2">{{$toLocal(order.wholePrice,0)}}</td>
                    <td class="resp-1">{{$G2J(order.createdAtDateTime)}}</td>
                    <td align="center" class="resp-3">
                        <!-- <span class="condition" v-if='isActive'>{{order.executedPercent}}%<img src="../../../assets/auth/trash.svg" /></span> -->
                        <span class="condition"><img :src="require('../../../assets/icons/'+statuses[order.orderStatus]+'.svg')"></span>
                        <!-- <span>{{order.orderStatus}}</span> -->
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- <div class="filters">
        <div class="right-filter">
            <DropFilter class="f-2" @choose="(value)=>{buy_sell=value.value}" :items="[{label:'نوع معامله',value:''},{label:'خرید',value:'BUY'},{label:'فروش',value:'SELL'}]" />
            <DropFilter class="f-3" @choose="(value)=>{coin=value}" :items="coins" />
        </div>

    </div>
    <div class="table-container">
        <div class="table-title">تاریخچه معاملات وب مانی و پرفکت مانی </div>
        <table>
            <tr class="table-header">
                <td>نوع سفارش</td>
                <td>رمزارز</td>
                <td class="resp-3">قیمت واحد<span class="tag">(تومان)</span></td>
                <td> مقدار</td>
                <td class="resp-3">قیمت کل<span class="tag">(تومان)</span></td>
                <td class="resp-1">زمان</td>
                <td class="resp-2">آدرس</td>
                <td class="resp-2">جزئیات</td>
                <td class="resp-3">وضعیت</td>
            </tr>
            <tbody>
                <tr class="table-row" v-for="(order,index) in filterOrders()" :key="index">
                    <td :class="{'depsoit-buy':order.tradeType=='BUY','withdraw-sell':order.tradeType=='SELL'}">{{dict[order.tradeType]}}</td>
                    <td>
                        <div class="coin-title">
                            <span>{{$coinLabel[order.coinName]}}</span>
                        </div>
                    </td>
                    <td class="resp-3">{{order.unitPrice.toLocaleString()}}</td>
                    <td dir='ltr'>{{order.amount}}&nbsp;$</td>
                    <td class="resp-3">{{order.totalPrice.toLocaleString()}}</td>
                    <td class="resp-1">{{order.date}}</td>
                    <td class="resp-1">{{order.address}}</td>
                    <td class="resp-1"><img src="../../../assets/auth/eye.svg" /></td>
                    <td class="resp-3" v-if="order.condition=='SUCCESS'"><img src="../../../assets/icons/success.svg" /></td>
                    <td class="resp-3" v-if="order.condition=='FAIL'"><img src="../../../assets/icons/fail.svg" /></td>
                </tr>

            </tbody>
        </table>
    </div> -->

</div>
</template>

<script>
import DropFilter from './DropFilter'
import {
    // tradeTo,
    // tradeFrom,
    Loop
} from "@/library/reuseableFunction";
export default {
    name: 'Trades',
    components: {
        DropFilter,
    },
    computed: {
        coins() {

            return Object.entries(this.$coins).filter(coin => coin[1].code && coin[0] != 'TOMAN').map(coin => {
                return {
                    label: coin[1].persianName,
                    value: coin[0]
                }
            })
        },
    },

    methods: {
        filterP2POrders(orderType, dealType) {
            return this.P2PDataSet.filter(order => {
                if (order.orderType.includes(`${orderType}_${dealType}`) && order.marketType.includes(this.coin2)) {
                    return order
                }
            })
        },
        filterExchangeOrders(dealType){
            return this.exchangeDataSet.filter(order => {
                if (order.orderType.includes(dealType) && order.marketType.includes(this.coin1)) {
                    return order
                }
            })

        },

        async removeorder() {
            //   this.state.loading = true
            this.cancelModal = false
            const [res, ] = await this.$http.delete(`orders/${this.removeid}`)
            if (res) {
                ++this.state.orderChange
                this.modalType = ''
                this.$error('سفارش با موفقیت حذف شد', '', 'success')
                if (this.state.loop) {
                    this.loop.start()
                } else {
                    this.getLastOrders()
                }
            }
        },
        cancelOrderModal(e) {
            this.loop.stop()
            this.modalType = 'CANCEL'
            this.removeid = e.id
        },
        tradeType(type) {
            return type.includes('EXCHANGE') ? 'صرافی' : type.includes('MARKET') ? 'بازار' : 'حد'
        },
        async getP2POrders() {
            // if (this.page <= this.lastPage) {
            this.page++
            let [res,] = await this.$http.get('/orders', {
                params: {
                    // market: this.tradeFrom + '_' + this.tradeTo,
                        type: 'P2P',

                    size: 20
                }
            })
            if (res.content)
                this.P2PDataSet = res.content;

            this.loading = false
        },  
        async getExchangeOrders() {
            // if (this.page <= this.lastPage) {
            this.page++
            let [res,] = await this.$http.get('/orders', {
                params: {
                    // market: this.tradeFrom + '_' + this.tradeTo,
                        type: 'EXCHANGE',

                    size: 20
                }
            })
            if (res.content)
                this.exchangeDataSet = res.content;
            this.loading = false
        },

        // },
    },
    mounted() {
        this.loading = true
        if (this.state.loop) {
            this.loop1 = new Loop(this.getExchangeOrders, 5000, 'lastOrder')
            this.loop2 = new Loop(this.getP2POrders, 5000, 'lastOrder')
            this.loop1.start()
            this.loop2.start()
        } else {
            this.getExchangeOrders()
            this.getP2POrders()
        }

    },
    beforeDestroy() {
        this.loop1.stop()
        this.loop2.stop()
    },
    data() {
        return {
             exchangeDataSet: [],
            P2PDataSet: [],
            exchangeDealType: '',
            P2PDealType: '',
            P2PIsBuy: true,
            marketType: '',
            currencyType: 'RIAL',
            is_open: true,
            buy_sell: '',
        
            coin1: '',
            coin2: '',
            marketTypes: [{
                    label: 'نوع بازار',
                    value: ''
                },
                {
                    label: 'بازار',
                    value: 'MARKET'
                },
                {
                    label: ' حد',
                    value: 'LIMITED'
                },
            ],
            dict: {
                'LIMITED': 'حد',
                'MARKET': 'بازار',
                'EXCHANGE': 'صرافی',
                'BUY': 'خرید',
                'SELL': 'فروش'
            },

            statuses: {
                FINISHED: 'success',
                IS_OPEN: 'success',
                CANCELLED_BY_ADMIN: 'fail',
                CANCELLED_BY_USER: 'fail',
                EXPIRED: 'fail',
                RAISED_BY_USER: 'success',
                PENDING: 'success',
                ACCEPTED: 'success',
                DONE: ' success',
                FAILED: 'fail',
                FINISHED_WITHOUT_TRANSACTION: 'success'
            },
            dealType: 'MARKET'

        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.f-1 {
    z-index: 22 !important;
}

.f-2 {
    z-index: 21 !important;
}

.f-3 {
    z-index: 20 !important;
}

.left-filter {
    flex-grow: 1;
    max-width: 400px;
}

.mar {
    width: 600px;
}

@media(max-width:1200px) {

    .resp-1 {
        display: none;
    }
}

@media(max-width:900px) {
    .resp-2 {
        display: none;
    }
}

@media(max-width:600px) {
    .resp-3 {
        display: none;
    }
}
</style>

<template>
  <div class="questions-sec">
       <diV> <div class="head"><div/>سوالات پرتکرار <div/></div></diV>
       <div class="faq">
           <div class="questions">
               
          <div class="question"  v-for="(question,index) in questions" :key="index" >
            <div class="row" >      
               
                    <div class="subject" @click="openedIndex=(openedIndex==index)?null:index">
                    <div  >{{question.question}}</div>
                     <div ><img  :class="{dropicon:true,dropopen:(openedIndex==index)}" src='../../assets/sidebar/arrowdown.svg'/> </div>       
                </div>
            </div>  
                    <div  :class="{description:true,'open-description':openedIndex==index}"><span>{{question.answer}}</span></div>
        </div>

           </div>
           <div class="question-img"><img src="../../assets/auth/question.png"/></div>
       </div>
       

  </div>
</template>

<script>
export default {
  name: 'FAQ',
  
        data(){
            return {
                openedIndex:null,
                questions:[]
            }
        },
    mounted(){
      this.getFaq()

        },
     methods:{
     async getFaq(){
            this.questions=await this.$axios.get('/faq')
        
     }
  
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.questions{
    display: flex;
    flex-direction: column;
    flex-basis: 300px;
    flex-grow: 3;
   
    row-gap: 12px;
}

.faq{
    display: flex;
    
    justify-content: space-between;
    
    align-items: flex-start;
    row-gap: 30px;
    margin-top: 32px;
    flex-wrap:wrap;
    width: 100%;
}


.head{
    display: flex;
    column-gap: 4px;
    margin-bottom: 24px;
    text-align: center;
    font-size: clamp(17px,1.7vw,20px);
    font-weight: 500;
    justify-content: center;
    align-items: center;
}

.question-img{
  
     flex-basis: 300px;
     max-width: 440px;

    flex-grow: 1;
  
  
    display: flex;
    
 
    
  
        justify-content: flex-end;

    flex-wrap: wrap;
}

.question-img > img{
    display: block;
       max-width: 407px;
    width: 100%;
    height:auto;
  
}
.head > div{
    border-bottom: 2px solid #F29A39;
    width: 16px;
    border-radius: 2px;}
   
.description{
     font-size: clamp(11px,1.3vw,14px);
    font-weight: 400;
    text-align: right;
    padding: 0px;
    height: 0px;
    overflow:hidden;
    transition:  all ease 0.6s;
    padding-left:14px;
    padding-right:14px;
    opacity: 0;
    visibility: hidden;
}
.open-description{
   
  height: 30px;
   visibility: visible;
   opacity: 1;
  
}
.question{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 48px;
    justify-content: center;
    border-radius: 2px;
      background: rgba(255, 255, 255, 0.05);
backdrop-filter: blur(15px);
cursor:pointer;


color: white;
}
.line{
     border-left: 4px solid #F29A39;;
     border-bottom-left-radius: 2px;
     display: flex;
   
    justify-content: center;
     height:48px;
     border-top-left-radius: 2px;
}

.row{
    display: flex;
    align-items: center;
    
    height: 48px;
  
}
.subject{
   
    padding-right:12px;
    padding-left:12px;
     font-size: clamp(14px,1.5vw,16px);
    font-weight: 500;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}
.dropopen{
    transform: rotate(180deg);
}


.dropicon{
    display: flex;
    align-items: center;
}


</style>

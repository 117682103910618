<template>
<div class="ContactUs">
    <div class="head">
        <div />تماس با ما
        <div />
    </div>
    <div class="passage">
        <div>اگر در بخش های مختلف سایت سوالی دارید لطفاً قبل از تماس با پشتیبانی، ابتدا پرسش‌های متداول را بخوانید، در صورتی که پاسخ خود را نیافتید می توانید در هر ساعت شبانه روز از طریق سیستم چت آنلاین (کادر نارنجی رنگ در پایین صفحات ) با کارشناسان پشتیبانی ما می‌توانید در ارتباط باشید و سوالات خود را مطرح کنید.
        </div>
        <div>با توجه به حجم بالای تماس ها ممکن است امکان پاسخگویی به تمام درخواست های شما وجود نداشته باشد. لذا خواهشمند است تا جای ممکن سوالات و مشکلات خود را با پشتیبانی آنلاین نوبیتکس مطرح کنید.
        </div>
    </div>
    <div class="images">
        <div class="map">
            <img src='../assets/auth/map.png' />
        </div>
        <div class="world-container">
            <div class="world-bg" />
            <div class="details">
                <div class="detail"><img class="contact-icon" src="../assets/auth/location.svg" />
                    <div>{{info.address}}</div>
                </div>
                <div class="detail"><img class="contact-icon" src="../assets/auth/phone.svg" />
                    <div>{{info.number}}</div>
                </div>
                <div class="detail "><img class="contact-icon" src="../assets/auth/email.svg" /> {{info.email}}</div>
                <div class="detail "><img class="contact-icon" src="../assets/auth/instagram.svg" />
                    <div>{{info.instagram}}</div>
                </div>
            </div>

        </div>

    </div>

</div>
</template>

<script>
export default {
    name: 'ContactUs',
    data() {
        return {
            info: {
                address: "",
                appAndroid: "",
                appIOS: "",
                email: "",
                instagram: "",
                linkedin: "",
                number: "",
                telegram: "",
                twitter: ""
            }
        }
    },
    mounted() {
        this.getInfo()
    },
    methods: {
        async getInfo() {
            const res = await this.$axios.get('/exchange-info')
            this.info = res.baseDTO || {
                address: '',
                appAndroid: '',
                appIOS: '',
                email: '',
                instagram: '',
                linkedin: '',
                number: '',
                telegram: '',
                twitter: ''
            }
        },
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.ContactUs {
    display: flex;
    justify-content: center;

    padding-bottom: 60px;
    row-gap: 24px;
    font-size: 16px;
    font-weight: 500;
    flex-direction: column;

    text-align: right;
    color: white;
}

.details {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    font-size: clamp(14px, 1vw, 16px);
    width: 100%;
    ;
    justify-content: space-between;
    padding: 16px;
    background-color: rgba(37, 42, 59, 0.8);
}

.detail {
    display: flex;
    font-weight: 500;
    column-gap: 6px;
    justify-content: flex-start;
    align-items: center;
}

.contact-icon {
    max-width: clamp(25px, 2vw, 32px);
    max-height: clamp(25px, 2vw, 32px);
}

.map {
    flex-basis: 400px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
}

.map>img {
    display: block;
    min-width: 300px;
    width: auto;
    height: 100%;
    max-width: min(700px, 100%);
}

.world-container {
    position: relative;
    display: flex;
    min-width: 300px;
    height: 264px;

    max-width: min(700px, 100%);
    flex-grow: 1;
    z-index: 1;
}

.world-bg {
    position: absolute;
    content: "";
    width: 100%;
    background-image: url('../assets/auth/world.png');
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
}

.images {
    display: flex;
    column-gap: 16px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    row-gap: 20px;
}

/* img{
    display: block;
    min-width: 300px;
    width: auto;
    height: 100%;
    max-width: min(700px,100%);
} */
.head {
    display: flex;
    column-gap: 4px;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: white;
}

.passage {
    display: flex;
    row-gap: 24px;
    flex-direction: column;
}

.head>div {
    border-bottom: 2px solid #F29A39;
    width: 16px;
    border-radius: 2px;
}
</style>

<template>
<div class="modal">
<Modal @close="$emit('modalToggle','')">
    <template #header>
        خرید {{$coins[order.destinationCoin].persianName}}
    </template>
    <template #body >
          <p >شماره کیف پولی که درج می کنید را چک کنید و از صحت آن مطمئن شوید. در صورت واریز به حساب دیگر سایت هیچ گونه مسئولیتی قبول نمی کند.</p>
        <div  class="opac-container">
                <div class="detail"><div> مجموع دریافتی شما:</div><div dir='ltr'>{{$toLocal(order.amount,$decimal[order.destinationCoin])}}&nbsp;<span class="tag">{{order.destinationCoin}}</span></div></div>
                <div class="detail"><div> قیمت واحد:</div><div>{{$toLocal(order.unitPrice,0)}}&nbsp;<span class="tag">{{$coins[order.baseCoin].persianName}}</span></div></div>
                <div class="detail"><div>پرداختی شما:</div><div>{{$toLocal(order.unitPrice*order.amount,0)}}&nbsp;<span class="tag">{{$coins[order.baseCoin].persianName}}</span></div></div>
         </div>
        <!-- <SwitchComp :theme=1 :choosed="'VISTA'" @choose="(choice)=>payMethod=choice"  :items="[{label:'فروش از کیف پول ویستا',value:'VISTA'},{label:'فروش از کیف پول دیگر',value:'OTHER'}]"/> -->
        <label-input v-if="payMethod=='OTHER'" :theme=1 label=" آدرس کیف پول مقصد "/>
    </template>
    <template #footer>
         <CardButton  @clicked="$emit('confirm')">خرید</CardButton> 
    </template>
   


   
</Modal>
  
</div>

</template>

<script>
// import SwitchComp from '../public/SwitchComp'
import Modal from './Modal'
export default {
  name: 'CryptoBuy',
  components:{
      Modal,
    //   SwitchComp
  },
  props:{
      order:Object
  }
  ,data(){
      return{

      payMethod:'VISTA'

      }
  }
 
  
}
</script>

<style scoped>
.modal{
    max-width: 444px;
}
.opac-container{
    background: rgba(136, 136, 136, 0.05);
border-radius: 2px;
width: 100%;
padding: 8px;
display: flex;
flex-direction: column;
row-gap: 8px;
}
.detail{
    display: flex;
    justify-content: space-between;
    font-size: clamp(12px,1.3vw,14px);
    color: #EFEFEF;
}
.tag{
    opacity: 0.7;
}
p{
    font-size: clamp(12px,1.3vw,14px);
}

</style>

<template>
<div class="wallet-page">
    <div class="table-filter">
        <SwitchFilter @choose="(choice)=>{walletType=choice}" :items="[{label:'بازار',value:'P2P'},{label:'صرافی',value:'EXCHANGE'}]" />

    </div>
    <table class='currencyTable' cellspacing="0" cellpadding="0">
        <div class="row">
            <div class="row-data">
                <div>
                    <div class="title">
                        <img class="coin-icon" :src="require('../../assets/trades/iranflag.png')" />
                        <div> موجودی ریالی</div>

                    </div>

                </div>
                <div  align="center">

                </div>
                <div  align="right">
                    {{$toLocal(tomanBalance,0)}} تومان

                </div>
            </div>
            <div  class="btns">
                <button class="withdrawBtn" @click="modalType='RialWithdraw'">برداشت</button>
                <button class="transactBtn" @click="modalType='RialDepsoit'">واریز</button>
            </div>

        </div>
        <div class="row" v-for="(coin,index) in sorted.filter(coin=>coin[0]!='TOMAN')" :key="index">
            <div class="row-data">
                <div>
                    <div class="title">
                        <img class="coin-icon" :src="require('../../../public/coins/'+coin[0]+'.png')" />
                        <div>{{$coins[coin[0]].persianName}}</div>
                        <div class="resp opac">{{$coins[coin[0]].code}}</div>

                    </div>

                </div>
                <div align="center">
                    <span>{{$toLocal(coin[1].credit,$decimal[coin[0]])}} </span>
                    <span class="resp">{{$coins[coin[0]].code}}</span>
                </div>
                <div align="right" class="resp ">
                    <div class="val">
                        {{$toLocal(coin[1].wholePriceOfCredit,0)}}
                        <div class="opac">تومان</div>

                    </div>

                </div>
            </div>
            <div  class="btns">
                <button class="tradeBtn" @click="$router.push('/panel/AdvTrade')">معامله</button>
                <button class="withdrawBtn" @click="openWithdrawModal(coin[0],coin[1].credit)">برداشت</button>
                <button class="transactBtn" @click="relatedCoin=coin[0];modalType='CryptoDepsoit'">واریز</button>
            </div>
        </div>
    </table>

    <CryptoWithdraw v-if="modalType=='CryptoWithdraw'" @modalToggle="value=>modalType=value" :relatedCoin="relatedCoin" :credit="credit" :isExchange='walletType=="EXCHANGE"' />
    <CryptoWithdrawConf v-if="modalType=='CryptoWithdrawConf'" @modalToggle="value=>modalType=value" />
    <CryptoDepsoit v-if="modalType=='CryptoDepsoit'" @modalToggle="value=>modalType=value" :relatedCoin="relatedCoin" :isExchange='walletType=="EXCHANGE"'/>
    <RialDepsoit v-if="modalType=='RialDepsoit'" @modalToggle="value=>modalType=value" />
    <RialWithdraw v-if="modalType=='RialWithdraw'" @modalToggle="value=>modalType=value" :cash="tomanBalance" />
    <!-- <RialWithdrawConf v-if="modalType==='RialWithdrawConf'"/> -->
</div>
</template>

<script>
import CryptoWithdraw from "../modals/CryptoWithdraw";
import CryptoWithdrawConf from "../modals/CryptoWithdrawConf";
import CryptoDepsoit from "../modals/CryptoDepsoit";
import RialDepsoit from "../modals/RialDepsoit";
import RialWithdraw from "../modals/RialWithdraw";
import SwitchFilter from '../tools/SwitchFilter'
// import RialWithdrawConf from "../modals/RialWithdrawConf";
export default {
    name: 'Wallet',
    components: {
        CryptoWithdraw,
        CryptoDepsoit,
        RialDepsoit,
        RialWithdraw,
        SwitchFilter,
        // RialWithdrawConf,
        CryptoWithdrawConf
    },
    watch: {
        'walletType': function () {
            this.getWallets()
        },
        modalType() {
            console.log(this.modalType)
        }
    },

    data() {
        return {
            modalType: '',
            wallets: {},
            errorFlag: false,
            walletType: 'P2P',
            relatedCoin:'',
            credit:0

        }
    },
    methods: {
        openWithdrawModal(code, credit) {
            this.relatedCoin = code;
            this.credit = credit
            console.log(credit)
            this.modalType = 'CryptoWithdraw';
        },

        openTomanWithdrawModal() {
            this.modalType = 'TomanWithdraw';
        },
        async getWallets() {
            this.state.loading = true

            const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=' + this.walletType)
            if (res) {
                // let entireRes = Object.entries(res)
                // const sortCoins = this.$walletSortCoins
                // entireRes.sort((a,b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
                this.wallets = res
            }
            this.loading = false

        },

    },
    computed: {
        tomanBalance() {

            if (this.wallets['TOMAN']) {
                const temp = this.wallets['TOMAN']
                return temp.credit
            }
            return 0
        },
        sorted() {
            let entireRes = Object.entries(this.wallets)
            const sortCoins = this.$walletSortCoins
            entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
            return entireRes
        }
    },
    mounted() {
        this.getWallets()
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.row {
    display: flex;
    /* flex-wrap: wrap; */
    font-size: clamp(14px,1.4vw,15px);
    padding: 4px 10px;
    align-items: center;
    margin-top: 5px;
    white-space: nowrap;
    background: rgba(136, 136, 136, 0.05);
    border-radius: 2px;
    color: #EFEFEF;

}

.row-data {
    grid-template-columns: 1fr 1fr 1fr;
    display: grid;
    column-gap: 10px;
    flex: 7;
    align-items: center;

}

.opac {
    opacity: 0.7;
    color: white;
    font-size: 14px;
}

.coin-icon {
    width: 45px;
}

.val {
    display: flex;
    column-gap: 5px;
}

.currencyTable {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 10px;
}

.btns {
    flex: 3;
    padding: 10px 0px;
    display: flex;
    column-gap: 12px;
    row-gap: 12px;
    justify-content: space-between;

}

.title>span {
    font-weight: 400;
    color: #1E2530;
    opacity: 0.6;
}



.title {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.tradeBtn {
    background: linear-gradient(258.51deg, #FF951F -13.62%, #F7BD68 62.17%, #FF951F 144.54%);
    color: white;
}

.withdrawBtn {
    background: rgba(214, 43, 43, 1);

    color: white;
}

button {
    font-weight: bold;
    opacity: 0.8;
    max-width: 112px;
    flex-grow: 1;
    font-size: clamp(13px,1.4vw,16px);
    min-width: 70px;
    padding: 6px 0px;
    border-radius: 2px;
}

button:hover {
    opacity: initial;
}

.transactBtn {
    background: #0B8710;
    color: white;

}

@media(max-width:800px) {
    .resp {
        display: none;
    }

    .row {
        grid-template-columns: 1fr 1fr;
    }
}

@media(max-width:640px) {
    .row{
        flex-direction: column;
        align-items: stretch;
        row-gap: 20px;
    }
}
</style>

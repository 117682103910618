<template>
<div class="switch">
    <div :class="{active:choice==item.value}" v-for="(item,index) in items " 
        :key="index" @click="choice=item.value;$emit('choose',choice)" >
        {{item.label}}
    </div>
</div>
  
</template>

<script>
export default {
  name: 'FilterSwitch',
  props:{
      items:{type:Array},
    
  },
  data(){
      return {
          choice:this.items[0].value
      }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.switch{
    display: flex;
    font-size: clamp(12px,1.3vw,14px);
    /* width: 100%; */
    flex-grow: 1;
    align-items: center;
    padding: 1px;
    background: rgba(136, 136, 136, 0.05);
    border-radius: 2px;
    color: #EFEFEF;
    
}
.switch > div{
      flex: 1;
      padding: 3px 17px;
      display: flex;
      justify-content: center;
      white-space: nowrap;
      cursor: pointer;
      opacity: 0.7;
      
    
}
.active{
     background: rgba(136, 136, 136, 0.05);
    border-radius: 2px;
      opacity:1!important;


    
}

</style>
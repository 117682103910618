<template>
  <div class="aboutus">
               <div class="head"><div/>  درباره ما <div/></div>
          <div>هدف ما در ویستا</div>
          <div>
              صرافی ویستا از اولین صرافی‌های ارز دیجیتال ایرانی است. این صرافی با ارائه خدمات متنوع و پشتیبانی 24 ساعته، پلتفرمی امن برای مبادلات ارزهای دیجیتال است. چهار سال سابقه در حوزه کریپتوکارنسی، ویستا را به یکی از پیشگامان و اثر گذاران بر بازار داخلی ارزهای دیجیتال در ایران تبدیل کرده است. ویستا، یک پلتفرم آنلاین برای خرید و فروش ارزهای دیجیتال است. این صرافی به‌عنوان یک واسطه میان خریداران و فروشندگان ارزهای دیجیتال عمل می‌کند. کاربران در ویستا با ارائه نرخ خرید و فروش مورد نظر خود با سایر کاربران اقدام به معامله می‌کنند
              </div>
          <div class="detail-img">
              <div class="details">
                  <div>
                      <div>تعداد معاملات</div>
                      <div>{{totaltrad.toLocaleString()}}</div>
                  </div>
                  <div>
                      <div>تعداد کاربران</div>
                      <div>{{userscount.toLocaleString()}}</div>
                  </div>
                  

              </div>

          </div>
          <div> 
              با بررسی بخش بازارها در وب‌سایت ویستا، می‌توانید از قیمت‌ لحظه‌ای ارزهای دیجیتال معتبر و محبوب جهانی که در ویستا عرضه شده‌اند باخبر شوید. قیمت‌ها در ویستا به صورت لحظه‌ای بر پایه بازار تتر (دلاری) و تومان قابل مشاهده است.

ویستا در حال حاضر نزدیک به 40 کوین معتبر دنیا از جمله بیت کوین (BTC) ، اتریوم (ETH) ، تتر(USDT) ، دوج کوین (DOGE) ، شیبا (SHIB) و بسیاری دیگر از توکن‌های محبوب را در پلتفرم معاملاتی خود دارد. امکان معامله این توکن‌ها بر دو پایه بازار ریالی، تتری با سایر کاربران وجود دارد.
          </div>

    
    
          <div>امنیت ویستا</div>
          <div>
              با بررسی بخش بازارها در وب‌سایت ویستا، می‌توانید از قیمت‌ لحظه‌ای ارزهای دیجیتال معتبر و محبوب جهانی که در ویستا عرضه شده‌اند باخبر شوید. قیمت‌ها در ویستا به صورت لحظه‌ای بر پایه بازار تتر (دلاری) و تومان قابل مشاهده است.

ویستا در حال حاضر نزدیک به 40 کوین معتبر دنیا از جمله بیت کوین (BTC) ، اتریوم (ETH) ، تتر(USDT) ، دوج کوین (DOGE) ، شیبا (SHIB) و بسیاری دیگر از توکن‌های محبوب را در پلتفرم معاملاتی خود دارد. امکان معامله این توکن‌ها بر دو پایه بازار ریالی، تتری با سایر کاربران وجود دارد.
          </div>

  
          <div>حفاظت از دارایی های دیجیتال کاربران</div>
          <div>
           با بررسی بخش بازارها در وب‌سایت ویستا، می‌توانید از قیمت‌ لحظه‌ای ارزهای دیجیتال معتبر و محبوب جهانی که در ویستا عرضه شده‌اند باخبر شوید. قیمت‌ها در ویستا به صورت لحظه‌ای بر پایه بازار تتر (دلاری) و تومان قابل مشاهده است.

ویستا در حال حاضر نزدیک به 40 کوین معتبر دنیا از جمله بیت کوین (BTC) ، اتریوم (ETH) ، تتر(USDT) ، دوج کوین (DOGE) ، شیبا (SHIB) و بسیاری دیگر از توکن‌های محبوب را در پلتفرم معاملاتی خود دارد. امکان معامله این توکن‌ها بر دو پایه بازار ریالی، تتری با سایر کاربران وجود دارد.       </div>

  
  </div>
</template>

<script>
export default {
  name: 'AboutUs',
  data(){
      return{
           userscount: '0',
            tradprice: '0',
            totaltrad: '0'
      }
  },
   methods: {
        async getInfo() {
            const res = await this.$axios('trades/market-info-home')
            this.userscount = res.baseDTO.userCount
            this.tradprice = res.baseDTO.tradesValue
            this.totaltrad = res.baseDTO.tradesAmount
        }
    },
    mounted() {
        this.getInfo()
    }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.aboutus{
    display: flex;
    flex-direction: column;
    color:white;
    text-align: right;
    width:100%;
    padding-bottom:60px ;
    font-size:clamp(13px,1.4vw,16px);
    font-weight: 400;
    row-gap: 28px;
}
.detail-img{
    position: relative;
    display: block;
    z-index: 1;
      min-height: 239px;
}


.head{
    display: flex;
    column-gap: 4px;
    text-align: center;
    font-size: clamp(17px,1.7vw,20px);
    font-weight: 500;
    justify-content: center;
    align-items: center;
}

.head > div{
    border-bottom: 2px solid #F29A39;
    width: 16px;
    border-radius: 2px;
}
.details{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    text-align: center;
    position: absolute;
    font-size:clamp(22px,2.5vw,36px);
    align-items: center;
}

.details > div{
    display: flex;
    flex-direction: column;
    flex-basis: 200px;
    white-space: nowrap;
  
}
.detail-img::after{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: -1;
    height: 100%;
    min-height: 239px;
    content: "";
    
    background-repeat: no-repeat;
    background-image: url("../assets/auth/middlephoto.png");
    background-size: cover;
}


</style>

<template>
<div class="trade-page">
<div class="card">
  <CardHeader><span v-if="buy_sell">شارژ پرفکت مانی</span><span v-else>فروش پرفکت مانی</span></CardHeader>
  <div class="body">
    <div class='row1'>
        <div :class='{buyBtn:buy_sell,btn:true}' v-on:click="buy_sell=true">خرید</div>
        <div :class="{sellBtn:!buy_sell,btn:true}" v-on:click="buy_sell=false">فروش</div>
    </div>
    <!-- <div class="row2"><div class="right"><img src='../../../assets/trades/perfectmoney.svg'/> <p :style="{marginRight:'8px'}">پرفکت مانی<span class="opac" :style="{marginRight:'8px'}">PM</span></p></div><div class="left"><p> {{price.toLocaleString()}} تومان </p></div></div> -->
    <TitlePrice :crypto="crypto"/>
    <div class="row3" v-if="buy_sell">
       <label> موجودی کیف پول: </label>
       <label> <u>{{balance.toLocaleString()}}</u> تومان</label>

    </div>
    <div :class="{row4:true,row4margin:!buy_sell}">
     <LabelInput :theme=2 :type="'number'" :label="'مقدار'"/>
     <LabelInput v-if="buy_sell" :theme=2 :type="'number'" :label="'پرداختی'"/>
     <LabelInput  v-else :theme=2 :type="'number'" :label="'دریافتی'"/>
     
     <!-- <input v-model="tPrice" v-on:change="value=tPrice/price"   type="number"/> -->
    </div>
    <div class="dataInput privateData">
       <LabelInput  :theme=2 :type="'text'" :label="(buy_sell)?'آدرس پرفکت مانی':'کیف پول پرفکت مانی سایت'" :disabled="true"/>
    </div>
     <SwitchComp :theme=2 v-if="buy_sell" :choosed="'WALLET'" :items="[{label:'پرداخت از کیف پول',value:'WALLET'},{label:'پرداخت از درگاه',value:'GATE'}]"/>
    </div>
     

<CardButton color="white" :background="(buy_sell)?'#0B8710':'#D62B2B'"  @clicked="(buy_sell)?$emit('showModal','crypto-buy'):$emit('showModal','crypto-sell')"><span v-if="buy_sell">خرید پرفکت مانی</span><span v-else>ثبت درخواست فروش پرفکت مانی</span></CardButton>
</div>
  </div>
</template>

<script>
import SwitchComp from '../../public/SwitchComp'
import TitlePrice from './components/TitlePrice'

export default {
    components: {
    SwitchComp,
    TitlePrice
  },
  
  name: 'PerfectMoney',
  data(){
    return{
      buy_sell:true,
      value:0,
      tPrice:0,
      price:40000,
      balance:165000,
      pmAddress:"",
      walletPay:true,
      crypto:{title:"پرفکت مانی",symbol:"PM",icon:"perfectmoney",price:7897983}
    }
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.opac{
    color: #1E2530;
    opacity: 0.6;
}
.buyBtn{
 background: #0B8710;
  opacity: 1!important;

color:white;
}


.trade-page{
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-wrap: wrap;
 
}

.header{
  width: 100%;
  line-height:41px;
  background: #F29A39;
  color:white;
border-top-left-radius: 2px;
border-top-right-radius: 2px;
}
.row3{
  display:flex;
  flex-wrap:wrap;
  justify-content: space-between;
  align-items: flex-end;
  margin-top:24px;
   flex-direction: row;
  width: 100%;
 
}

.row4{
    display:flex;
    
   column-gap: 8px;;
   row-gap: 8px;
   margin-top:4px;
  width: 100%;
}

.row4margin{
  margin-top:24px;
}

.row4 > div{
  display: flex;
  
  flex-grow:1;

}
.dataInput{
    flex-wrap:wrap;
  display:flex;
  text-align: right;
  width:100%;
  box-sizing: border-box;
  
}

.body{
  padding:12px;
  display:flex;
  background: rgba(136, 136, 136, 0.05);
  color: #EFEFEF;
  flex-direction: column;
  align-items: center;
}

.sellBtn{
  background: #D62B2B;
  opacity: 1!important;
color: white;
}

.row1{
  color: white;

display: flex;
cursor: pointer;
height:41px;
width:180px;
background: rgba(37, 67, 130, 0.06);
border-radius: 2px;
}

.btn{
  display: flex;
  flex: 1;
  opacity: 0.7;
  justify-content: center;
  border-radius: 2px;
  align-items: center;
vertical-align: middle;

font-style: normal;

}
.card{
 
 width:min(92%,444px);
border-radius: 2px;
font-style: normal;
}


</style>

<template>
     <div class="page">
      <CardHeader>اطلاعات فردی</CardHeader>
      <div class="cardbody">
        <div class="personal-information">
         
          <div>
            <label>نام *</label>
            <input class="input" :class="{disabled:readOnly}" :disabled="readOnly"  @input="data.firstName=$S2Fa($event.target.value)" :value="data.firstName" type="text" />
          </div> 
          <div>
            <label>نام خانوادگی*</label>
            <input class="input" :disabled="readOnly" :class="{disabled:readOnly}" :value="data.lastName" @input="data.lastName=$S2Fa($event.target.value)"  type="text" />
          </div>
          <div >
            <label>کد ملی*</label>
            <input  :disabled="readOnly" :class="{disabled:readOnly}" @input="data.nationalCode=$A2N($event.target.value)"   :value='data.nationalCode' class="input" type="text" />
          </div>
          
          <div>
            <label>تلفن همراه*</label>
            <input @input="data.mobileNumber=$A2N($event.target.value)" :class="{disabled:readOnly}" :disabled="readOnly"  :value="data.mobileNumber" class="input" type="text" />
          </div>
          <!-- <div  :style="{opacity: '0.6'}">
            <label >آدرس ایمیل</label>
            <input dir="ltr" class="input" type="email" value="example@gmail.com" disabled/>
          </div> -->
          <div>
            <label>تلفن ثابت*</label>
            <input @input="data.telephone=$A2N($event.target.value)"  :value="data.telephone" class="input" type="text" />
          </div>
          <CalendarInput :disabled="readOnly" @input="value=>data.birthDay=value" :value="data.birthDay" label="تاریخ تولد*" />
          <div class="address">
            <label>آدرس*</label>
            <textarea class="textarea"  @input="data.address=$A2N($event.target.value)"    :value="data.address" rows="5" type="textarea" />
          </div>
          </div>
        
      </div>

    </div>

</template>

<script>
import CalendarInput from '../../tools/CalendarInput'
export default {
  name: 'HelloWorld',
  components:{
    CalendarInput
  },
  props: {
     data:Object,
     readOnly:Boolean
     },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

textarea:focus {
  outline: none !important;
  border: 1px solid #f29a39;
  color: white;
}

.address{
  flex-grow: 3;
  width: 100%;
  display: flex;
  flex-direction: column;

  
}
.disabled{
  opacity: 0.6  ;
}

.personal-information {
  display:flex;
  row-gap: 24px;
  column-gap: 54px;
  justify-content: center;
  flex-wrap: wrap;
  color: white;

}
.personal-information > div{
 flex-grow: 1;
 flex-basis:290px;
}

</style>

<template>
<div class="page">
    <CardHeader>اطلاعات بانکی</CardHeader>
    <div class="cardbody">
        <div class="bank-information">
            <div>
                <label>شماره کارت</label>
                <input v-model="creditCard.cardNumber" @input="cardNumberHandle(creditCard.cardNumber)" type="number" class="input" />
            </div>
            <div>
                <label>شماره شبا</label>
                <input v-model="creditCard.shebaCode" class="input" />
            </div>
            <div>
                <label>نام بانک</label>
                <div class="addbank">
                    <input :value="creditCard.bankName" type="text" class="btninput"  disabled='true'/>

                    <button @click="addAccount()" :disabled="!creditCard.shebaCode || 
                    !creditCard.cardNumber || this.creditCard.bankName=='کارت نامعتبر'">
                        افزودن کارت
                    </button>
                </div>
            </div>

        </div>
        <table id="cards">
            <tr class="table-header">
                <td align="right">نام بانک</td>
                <td class="resp-2">شماره کارت</td>
                <td class="resp-1">شماره شبا</td>
                <!-- <td>وضعیت</td> -->
                <td>جزئیات</td>

            </tr>

            <tr class="row" v-for="(bankAccount,index) in bankAccounts" :key="index">
                <td align="right">{{bankAccount.bankName}} </td>
                <td class="resp-2">{{bankAccount.cardNumber}}</td>
                <td class="resp-1">{{bankAccount.shebaCode}}</td>
                <!-- <td><div v-if="card.condition==0">درحال بررسی</div><div :style="{color:'#0B8710'}" v-if="card.condition==1">تایید شده</div></td> -->
                <td><img @click="removeAccount(index)" class="icon" src='../../../assets/authentication/delete.svg' /></td>
            </tr>

        </table>
    </div>
</div>
</template>

<script>
export default {
    name: 'HelloWorld',
    props: {
        bankAccounts: Array
    },
    data() {
        return {
            creditCard: {
                bankName: "",
                shebaCode: "",
                cardNumber: ""
            },
            banks: {
                '603799': 'بانک ملی ایران',
                '589210': 'بانک سپه',
                '627648': 'بانک توسعه صادرات',
                '207177': 'بانک توسعه صادرات',
                '627353': 'بانک تجارت',
                '585983': 'بانک تجارت',
                '627961': 'بانک صنعت و معدن',
                '603770': 'بانک کشاورزی',
                '639217': 'بانک کشاورزی',
                '628023': 'بانک مسکن',
                '627760': 'پست بانک ایران',
                '502908': 'بانک توسعه تعاون',
                '627412': 'بانک اقتصاد نوین',
                '622106': 'بانک پارسیان',
                '639194': 'بانک پارسیان',
                '627884': 'بانک پارسیان',
                '502229': 'بانک پاسارگاد',
                '639347': 'بانک پاسارگاد',
                '639599': 'بانک قوامین',
                '504706': 'بانک شهر',
                '502806': 'بانک شهر',
                '603769': 'بانک صادرات',
                '610433': 'بانک ملت',
                '991975': 'بانک ملت',
                '589463': 'بانک رفاه',
                '502938': 'بانک دی',
                '639607': 'بانک سرمایه',
                '627381': 'بانک انصار',
                '505785': 'بانک ایران زمین',
                '636214': 'بانک آینده',
                '636949': 'بانک حکمت ایرانیان',
                '621986': 'بانک سامان',
                '639346': 'بانک سینا',
                '606373': 'بانک قرض الحسنه مهر',
                '627488': 'بانک کارآفرین',
                '502910': 'بانک کارآفرین',
                '505416': 'بانک گردشگری',
                '639370': 'بانک مهر اقتصاد',
                '628157': 'موسسه اعتباری توسعه',
                '505801': 'موسسه اعتباری کوثر',
            }

        }
    },

    methods: {
        cardNumberHandle(value) {
            let temp = Object.entries(this.banks).filter(bank => {
                if (value.includes(bank[0]) || bank[0].includes(value))
                    return bank[0]
            })[0]
            
            this.creditCard.bankName =temp? this.banks[temp[0]] : 'کارت نامعتبر'
            
        },
        removeAccount(index) {
          if(this.bankAccounts.length>1)
            this.bankAccounts.splice(index, 1)
        },
        addAccount() {
            if (this.creditCard.shebaCode && this.creditCard.cardNumber) {
                this.bankAccounts.push(JSON.parse(JSON.stringify(this.creditCard)));
                this.creditCard = {
                    bankName: "",
                    shebaCode: "",
                    cardNumber: ""
                }
            }
        },
        dash(e) {
            this.bankAccounts[e].bankName = this.banks[this.bankAccounts[e].cardNumber ? this.bankAccounts[e].cardNumber.replace(/-/ig, '').slice(0, 6) : ''] || 'کارت نامعتبر'
            this.bankAccounts[e].cardNumber = this.$separator(this.bankAccounts[e].cardNumber)
            this.error = ''
        }
    },
    mounted() {
        // eslint-disable-next-line no-unused-vars
        for (let [index, item] of this.bankAccounts.entries()) {
            this.dash(index)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.addbank>button {
    height: 41px;

    font-weight: 500;
    background: linear-gradient(258.51deg, #FF951F -13.62%, #F7BD68 62.17%, #FF951F 144.54%);
    width: 100%;
    border-style: hidden;
    border-radius: 2px;

    font-style: normal;
    font-weight: normal;
}

#cards {
    border-collapse: collapse;

    text-align: center;
    border-radius: 2px;
}

.delete-icon {
    cursor: pointer;
}

td:first-child {
    padding-right: 15px;
}

th {
    margin-right: 10px;
}

.table-header {
    background-color: rgba(242, 154, 56, 0.04);
    border-radius: 2px;
    height: 41px;
}

table tr td:first-child {
    padding-right: 30px;
}

table tr td:last-child {
    padding-left: 16px;
    text-align: left;

}

.addbank {

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 28px;
}

.addbank>* {
    flex: 1;
}

.bank-information {
    text-align: right;
    display: flex;
    column-gap: 40px;
    justify-content: center;
    color: white;
    flex-wrap: wrap;

    font-weight: normal;
}

.bank-information>div {
    flex-grow: 1;
}

.row {

    font-size: 14px;
    font-weight: 400;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 2px;
}

tr {
    border-top: 2px solid rgba(255, 255, 255, 0.05);
    border-radius: 2px;
}

@media(max-width:900px) {
    .resp-1 {
        display: none;
    }
}

@media(max-width:550px) {
    .resp-2 {
        display: none;
    }
}

@media(max-width:400px) {
    .resp-3 {
        display: none;
    }
}
</style>

<template>
<div class="terms">
    <div>
        <div class="head">
            <div />قوانین و مقررات
            <div />
        </div>
        <div class="text">
            صرافی ویستا با تابعیت از قوانین جمهوری اسلامی ایران بستری برای تبادل دارایی‌های دیجیتال مانند بیت کوین، لایت کوین و اتریوم با ریال می‌باشد. تبادلات ارزی اعم از خرید و فروش دلار یا سایر ارزهای کاغذی، در این بستر صورت نمی گیرد.

            تعهدنامه فعالیت در بستر تبادل دارایی‌های دیجیتال صرافی ویستا

            کاربران موظفند قبل از ثبت‌نام تمامی مفاد این تعهدنامه را مطالعه نموده و صرفا در صورت پذیرش آن اقدام به ثبت نام نمایند.

            کاربران سایت می‌پذیرند که کلیه‌ی فعالیت‌های آن‌ها در صرافی ویستا در چارچوب قوانین جمهوری اسلامی بوده و هیچ گونه فعالیتی خارج از این چارچوب انجام نخواهند داد.
            احراز هویت برای استفاده از خدمات صرافی ویستا ضروری بوده و کاربران موظفند اطلاعات صحیح خود را بارگذاری نمایند.<br/> بدیهی است در صورت وجود هرگونه تخلف در احراز هویت، مسئولیت به عهده‌ی فرد متخلف بوده و صرافی ویستا حق توقف ارائه خدمات به کاربر مذبور و ارجاع موارد تخلف به مراجع ذی صلاح را خواهد داشت. صرافی ویستا خود را ملزم به حفظ اطلاعات شخصی کاربران خود می‌داند.
            کاربران صرافی ویستا تعهد می دهند که از خدمات سایت صرافی ویستا تنها برای خود استفاده نموده و مسئولیت استفاده از خدمات صرافی ویستا برای فرد غیر که فرآیند احراز هویت را طی نکرده باشد به عهده کاربر خواهد بود.<br/> شماره حساب اعلام شده به سایت و همچنین آدرس کیف پول ها جهت برداشت رمزارز نیز می بایست متعلق به کاربر بوده و کاربران مجاز به دادن آدرس کیف پول متعلق به اشخاص دیگر نیستند.
            کاربران صرافی ویستا می‌پذیرند در صورت تشخیص کارشناسان پشتیانی صرافی ویستا، جهت حفظ امنیت دارایی حساب کاربریشان با اطلاع قبلی نسبت به برقراری تماس تصویری با تیم صرافی ویستا همکاری نمایند. 
            همچنین بنا به دستور نهادهای قضایی مانند پلیس فتا، ممکن است برداشت رمزارز به مدت 24 تا 72 ساعت کاری پس از زمان واریز از طریق درگاه شتابی، محدود گردد.<br/>
            کاربران صرافی ویستا اذعان می‌دارند که از ریسک‌های مربوط به سرمایه‌گذاری در ارزهای دیجیتال مطلع بوده و با علم به این موضوع اقدام به سرمایه‌گذاری و تبادل می‌نمایند.<br/>
            صرافی ویستا به عنوان بازار آنلاین تبادل ارزهای دیجیتال، هیچ گونه مسئولیتی در قبال نحوه‌ی معاملات کاربران و سود و زیان حاصل از آن ندارد.<br/>

            هرگونه سهل انگاری کاربران در حفظ اطلاعات امنیتی از جمله گذرواژه کاربر، به عهده‌ی شخص کاربر بوده و صرافی ویستا هیچ مسئولیتی به عهده نخواهد داشت. اکیداً توصیه می شود از گذرواژه پیچیده و امن و کد شناسایی دوعاملی استفاده شود.
            صرافی ویستا این اطمینان را می‌دهد که دارایی‌های کاربران را نزد خود به امانت و به بهترین شکل و با بالاترین استانداردهای امنیتی ممکن، حفظ نماید. در صورت بروز هرگونه مشکل امنیتی، صرافی ویستا متعهد به جبران خسارت خواهد بود.
            در صورت تمایل برای برداشت ارزهای دیجیتال، مسئولیت ارائه‌ی آدرس صحیح کیف پول به عهده‌ی کاربر خواهد بود. در صورت بروز هر گونه مشکل اعم از اشتباه در ورود آدرس صحیح، نقص آدرس، مشکلات کیف پول مقصد و بلوکه شدن دارایی‌های کاربران در کیف پول مقصد، هیچ گونه مسئولیتی به عهده ی صرافی ویستا نخواهد بود.
            صرافی ویستا در مقابل واریز توکن یا کوین بر بستر اشتباه یا کوین هایی که در صرافی ویستا پشتیبانی نمی شود هیچ گونه مسئولیتی نداشته و با توجه به مکانیسم ذخیره سازی سرد امکان استخراج این توکن ها با استفاده از ولت ثالث وجود ندارد. لذا مسئولیت هرگونه واریز اشتباه با خود کاربر بوده و کاربر حق هیچ گونه شکایتی را نخواهد داشت.
            درخواست برداشت ریال از حساب کاربری در سریع‌ترین زمان ممکن پس از ثبت، بررسی خواهد شد.<br/> زمان واریز پول به حساب کاربران بر اساس محدودیت‌های انتقال وجه بین بانکی، متفاوت خواهد بود. برای اطلاعات بیش‌تر، به قوانین انتقال وجه بین بانکی ( پایا ، ساتنا) مراجعه فرمایید.
            کاربر می‌پذیرد که به جز در موارد مورد تعهد صرافی ویستا ، حق هیچ گونه داعیه، طلب و شکایت از سایت صرافی ویستا ، مدیران، کارمندان و افراد مرتبط با این سایت را نخواهد داشت.
            اگر صرافی ویستا تحت هر عنوان اشتباهاً یا من غیر حق، وجوه یا رمزارزی را به حساب کاربر منظور یا در محاسبات خود هر نوع اشتباهی نماید، هر زمان مجاز و مختار است راساً و مستقلاً و بدون انجام هیچ گونه تشریفات اداری و قضائی و دریافت اجازه کتبی از متعهد (صاحب حساب) در رفع اشتباه و برداشت از حساب‌های وی اقدام نماید و تشخیص صرافی ویستا نسبت به وقوع اشتباه یا پرداخت بدون حق و لزوم برداشت از حساب معتبر خواهد بود و کاربر حق هرگونه اعتراض و ادعایی را در خصوص نحوه عملکرد صرافی ویستا از هر جهت از خود ساقط می نماید.
            در صورت بروز هرگونه مشکل یا ابهام در هر یک از معاملات، صرافی ویستا حق دارد مستقلاً آن معامله را ابطال و دارایی‌های هر یک از طرفین را به حساب خودشان عودت دهد.<br/> بدیهی است که در صورتی که اشکال از سمت صرافی ویستا باشد، موظف خواهد بود که جبران خسارت نماید و در غیراین صورت کاربر حق هرگونه اعتراض و ادعایی را در خصوص نحوه عملکرد صرافی ویستا از هر جهت از خود ساقط می‌نماید.
            در صورت هرگونه سوء استفاده از کد ریفرال صرافی ویستا در فعالیت‌های خلاف قانون، تمامی مسئولیت آن به عهده کاربر بوده و صرافی ویستا هیچ گونه مسئولیتی در قبال هرگونه سوء استفاده از سیستم معرفی خود به هر نحو ندارد. همچنین صرافی ویستا حق باطل کردن کد معرف و بستن حساب در صورت مشاهده هرگونه سوء استفاده بدون نیاز به دستور قضایی را خواهد داشت.<br/>
            در صورت فوت یا حجر کاربر، صرافی ویستا این حق را خواهد داشت به محض اطلاع از آن نسبت به واریز وجوه ریالی کاربر نزد شرکت، به حساب اعلام شده ایشان در زمان ثبت نام اقدام نموده و در خصوص دارائی رمز ارز نیز این حق و اختیار به شرکت واگذار گردید که نسبت به تبدیل آن به ریال معادل قیمت روز و واریز آن به حساب فوق اقدام نماید و این حق و امتیاز توسط کاربر به شرکت صلح و اعطا گردید. لازم به ذکر است ملاک زمانی محاسبه قیمت فروش دارائی رمز ارز کاربر، براساس ارائه اسناد مثبته توسط ذینفع و تایید اصالت آن توسط واحد حقوقی صرافی ویستا است. کاربر با علم و اطلاع از مقررات این بند نسبت به افتتاح حساب کاربری در صرافی ویستا اقدام نموده و کاربر، اشخاص ثالث، ورثه یا قائم مقام قانونی ایشان حق اعتراض و یا ادعایی در آینده در این خصوص را نخواهند داشت.<br/>

        </div>
    </div>
    <div class="coins">

        <img src='../assets/auth/three-coin.svg' />
    </div>

</div>
</template>

<script>
export default {
    name: 'Terms',
    methods: {
        async getInfo() {
            const res = await this.$axios.get('/exchange-info')
            this.privacyPolicy = res.baseDTO.privacyPolicy || ''
        },
    },
    data() {
        return {
            privacyPolicy: ''
        }
    },
    mounted() {
        this.getInfo()
    }
}
</script>

<style scoped>
.head {
    display: flex;
    column-gap: 4px;
    margin-bottom: 24px;
    text-align: center;
    font-size: clamp(17px, 1.7vw, 20px);
    font-weight: 500;
    justify-content: center;
    align-items: center;
}

.head>div {
    border-bottom: 2px solid #F29A39;
    width: 16px;
    border-radius: 2px;
}

.terms {
    text-align: right;
    flex-wrap: wrap;
    color: white;
    justify-content: center;
    display: flex;

    row-gap: 20px;
    width: 100%;
    padding-bottom: 93px;

}

.text {
    max-width: 800px;
    display: flex;
    font-size: clamp(13px, 1.4vw, 16px);
    font-weight: 400;
    justify-content: center;
    flex-direction: column;
    row-gap: 8px;
}

.coins {
    display: flex;
    flex-wrap: wrap;

}

img {
    height: auto;
    display: block;
    width: 100%;
}
</style>

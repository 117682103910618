<template>
<div class="title-price" >
    <div class="title-sec" >
        <div class="cryptoTitle" @click="(type=='ezDeal')?cryptoListShow=!cryptoListShow:''">
            <img  class="icon" :src="require('../../../../../public/coins/'+crypto.destCoinSymbol+'.png')" />
            <p :style="{ marginRight: '8px' }">
                {{$coins[crypto.destCoinSymbol].persianName}}
                <span class="symbol" :style="{ marginRight: '8px' }">
                    {{$coins[crypto.destCoinSymbol].code}}
                </span>
            </p>
        </div>
        <img @click="cryptoListShow=!cryptoListShow" v-if="type=='ezDeal'" :class="{ dropicon: true, dropopen: cryptoListShow }" src="../../../../assets/trades/arrowdown.svg" />
        <div class="crypto-dropdown" v-if="cryptoListShow">
            <!-- <div class="searchbox">
              <input class="crypto-filter" v-model="regex" /><img src="../../../../assets/auth/search.svg" />
            </div> -->
            <div class="cryptoList">
                <div v-for="(cryptoItem, index) in filteredCryptos" :key="index" class="cryptoItem" @click="select(cryptoItem)">
                    <div class="title">
                        <img class="icon" :src="   require('../../../../../public/coins/' +cryptoItem.destCoinSymbol + '.png')   " />
                        <p :style="{ marginRight: '8px' }">
                            {{ $coins[cryptoItem.destCoinSymbol].persianName }}
                            <span class="opac">{{ $coins[cryptoItem.destCoinSymbol].code }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div :class="{line:true,line2:type=='ezDeal'}" />
    <div class="price-sec">
        <p>{{ isBuy?$toLocal(crypto.buyPrice,0):$toLocal(crypto.sellPrice,0)}} {{$coins[baseCoin].persianName}}</p>
    </div>

</div>
</template>

<script>
export default {
    name: "TitlePrice",
    props: {
        cryptos: Array,
        type: String,
        isBuy:Boolean,
        baseCoin:String
    },
    computed: {
        filteredCryptos() {
            return this.cryptos.filter((coin) => coin.destCoinSymbol != this.crypto.destCoinSymbol);
        },
    },
    data() {
        return {
            regex: "",
            cryptoListShow: false,
            crypto: this.cryptos[0]

        }
    },
    watch:{
      cryptos(){
        this.crypto=this.cryptos[0]
      }
    },
    methods:{
      select(coin){
        this.crypto = coin;
        this.cryptoListShow = false
        this.$emit('selectCoin',this.crypto)
      }
    }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.title-price {
    display: flex;
    position: relative;
    white-space: nowrap;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 18px;
    width: 100%;
    min-height: 64px;
    flex-grow: 1;

    background: rgba(37, 67, 130, 0.06);
    border-radius: 2px;

}

.icon {
    height: 30px;
    width: 30px;
}

.line {
    border-left: 2px solid #EFEFEF;
    opacity: 0.2;
    min-height: 50px;
    border-radius: 2px;

}

.title-sec {
    display: flex;
    white-space: nowrap;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    font-size: clamp(15px, 1.3vw, 18px);
    padding-right: 8px;
    padding-left: 8px;
    cursor: pointer;

}

.crypto-dropdown {
    background: #252a3b;
    display: flex;
    top: 60px;
    position: absolute;
    color: white;
    box-shadow: 0px 0px 10px rgba(231, 167, 67, 0.2);
    border-radius: 2px;
    flex-direction: column;
    z-index: 2;

}

.cryptoList {
    flex-grow: 1;
    width: 100%;
    padding: 0px 10px 0px 10px;

    max-height: 150px;
    flex-direction: column;
    overflow-y: auto;
    /* Little Shadow */

}

.cryptoItem {
    display: flex;
    column-gap: 10px;
    font-size: 14px;
    width: 100%;

    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid rgba(171, 172, 173, 1);
    cursor: pointer;
}

.dropicon {
    margin-left: 5px;
}

.title {
    display: flex;
    align-items: center;
}

.cryptoTitle {
    display: flex;
    font-size: clamp(13px, 1.3vw, 16px);
    align-items: center;

}

.dropopen {
    transform: rotate(180deg);
}

.price-sec {
    display: flex;
    align-items: center;
    flex: 1;
    font-size: clamp(13px, 1.3vw, 18px);
    font-weight: bold;
    justify-content: flex-end;
    padding-left: 12px;
}

.symbol {
    color: #1E2530;
    opacity: 0.6;
    font-size: clamp(12px, 1.1vw, 14px);
}

.searchbox {
    display: flex;
    padding: 4px 10px 0px 10px;
    position: relative;
    align-items: center;
    width: 100%;
}

.searchbox>input {
    background: rgba(242, 154, 56, 0.04);
    border-radius: 2px;
    width: 100%;
    color: white;
}

.searchbox>img {
    position: absolute;
    right: 10px;
    padding: 5px;
}

@media (max-width:350px) {
    .line {
        border-left: 0px;
        border-top: 1px solid #EFEFEF;
        width: 80%;
        min-height: 1px;
    }

}

@media (min-width:597px) and (max-width:1250px) {
    .line2 {
        border-left: 0px;
        border-top: 1px solid #EFEFEF;
        width: 80%;
        min-height: 1px;
    }

}
</style>

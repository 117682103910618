<template>
<div class="chart">
    <apexchart type="donut" :key='series.length+""+change' width="350" :series="series" :options="options"></apexchart>

</div>
</template>

<script>
export default {
    name: 'ApexChart',
    props: ['walletType'],
    watch: {
        'walletType': function () {
            if (this.walletType == 'EXCHANGE') {
                this.getExchangeCoins()
            } else
                this.getP2PCoins()
            this.chart()

        },
        wallets() {
            this.chart()
        }
    },
    mounted() {
        this.getP2PCoins()
    },
    data() {
        return {
            wallets: {},
            loading: false,
            value: 30880097,
            change: 0,
            series: [0],

            options: {

                xaxis: {
                    type: "numeric"
                },
                colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#66DA26', '#FF9800', '#546E7A', ],
                labels: ['Apples', 'Oranges', 'Berries', 'Grapes', 'Apples', 'Oranges', 'Berries', 'Grapes', ],

                dataLabels: {
                    enabled: false,
                },
                states: {
                    hover: {
                        filter: {
                            type: 'none',

                        }
                    },

                },

                legend: {
                    show: false
                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    colors: ['#252A3B'],
                    width: 2,
                    dashArray: 0,
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: '70%',
                            labels: {
                                show: true,

                                name: {
                                    show: true,
                                    fontSize: '14px',
                                    fontFamily: 'IRANSans',
                                    // color: 'white',
                                    fontWeight: 600,
                                    offsetY: -17,
                                    formatter: function (val) {
                                        return val.name || val
                                    }
                                },
                                value: {
                                    show: true,
                                    fontSize: '14px',
                                    fontFamily: 'IRANSans',
                                    fontWeight: 400,
                                    offsetY: 10,
                                    color: 'white',
                                    formatter: function (val) {
                                        return parseFloat(val).toLocaleString() + '\n' + 'تومان'
                                    }
                                },
                                total: {
                                    show: true,
                                    showAlways: false,
                                    label: 'ارزش کل دارایی',
                                    fontSize: '14px',
                                    fontFamily: 'IRANSans',
                                    color: 'white',
                                    fontWeight: 600,
                                    formatter: function (w) {
                                        w = w.globals.seriesTotals.reduce((a, b) => a + b)
                                        return Math.floor(w).toLocaleString() + '\n' + 'تومان'
                                    }
                                }
                            }
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                    fillSeriesColor: false,
                    theme: 'dark;',
                    fixed: {
                        enabled: true,
                        position: 'bottomRight',

                    },
 
                    custom: function ({
                        seriesIndex,
                        w
                    }) {
                        // var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                        // console.log(seriesIndex)
                        // series
                        return (
                            '<div class="tooltip"> <div class="tooltip-comp"><div class="container">' +
                            `${w.globals.seriesNames[seriesIndex].name}`+
                             `<div class='tooltip-row' dir='ltr'>${w.globals.seriesNames[seriesIndex].value.toLocaleString()}<div class="tooltip-tag">
                             ${w.globals.seriesNames[seriesIndex].code}</div></div>`  + 
                             `<div class='tooltip-row' >${w.globals.series[seriesIndex].toLocaleString()}<div class="tooltip-tag">
                             تومان</div></div>`  +
                            '</div></div></div>'
                            //  '<ul>' +
                            // '<li><b>Price</b>: ' + data.x + '</li>' +
                            // '<li><b>Number</b>: ' + data.y + '</li>' +
                            // '<li><b>Product</b>: \'' + data.product + '\'</li>' +
                            // '<li><b>Info</b>: \'' + data.info + '\'</li>' +
                            // '<li><b>Site</b>: \'' + data.site + '\'</li>' +
                            // '</ul>'
                        );
                    },

                }

            },
        }
    },
    methods: {
        chart() {
            let index = 0
            Object.entries(this.wallets).forEach((e) => {
                    this.options.labels[index] = {
                        'name': this.$coins[e[0]].persianName,
                        'value': e[1].credit || 0,
                        'code':  this.$coins[e[0]].code,
                    }

                    this.series[index] = e[1].wholePriceOfCredit || e[1].totalBalance || 0
                    index++
                })
                ++this.change
        },
        async getP2PCoins() {
            this.loading = true
            this.state.loading = true

            const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=P2P')
            if (res) {
                this.wallets = res
            }

            this.loading = false
            this.state.loading = false

        },
        async getExchangeCoins() {
            this.loading = true
            this.state.loading = true

            const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=EXCHANGE')
            if (res) {
                this.wallets = res
            }

            this.loading = false
            this.state.loading = false

        }
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.chart {
    display: flex;
    font-size: 14px;
    color: white;
    justify-content: center;
    position: relative;
    align-items: center;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 300px;
}

.opac {
    opacity: 0.7;
}

.subtitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 16px;
    position: absolute;
}
::v-deep .tooltip-row{
  display: flex;
  justify-content: center;
  column-gap: 4px;
}
::v-deep .tooltip-comp {
    clip-path: polygon(0 100%, 10% 80%, 10% 0, 100% 0, 100% 95%, 20% 95%);
    color: white;
    padding: 8px;
    border-radius: 2px;
    background: #252A3B;
    width: 140px;
    font-size: 14px;
    height: 86px;

}

::v-deep .tooltip {
    clip-path: polygon(0 100%, 10% 80%, 10% 0, 100% 0, 100% 95%, 20% 95%);
    background: goldenrod;
    margin-left: 130px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 143px;
    height: 89px;

}

::v-deep .tooltip-tag{
    color: #EFEFEF;
    font-size: 12px;
    opacity: 0.7;
}

::v-deep .container {
    display: flex;
    flex-direction: column;

    margin-left: 12px;
}
</style>
